













































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'TableInput',
  components: {STextarea},
  mixins: [],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showStepColumn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    stepColumnHeader: {
      type: String as PropType<string>,
      required: false,
      default: function () {
        return this.$t('tableInput.step');
      },
    },
    customHeaders: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    columns: {
      type: Array as PropType<Array<{label: string; component: any; prop: string; width?: string}>>,
      default: () => [{label: 'Data', component: STextarea, prop: 'data'}],
    },
    modelValue: {
      type: Array as PropType<Array<Record<string, any>>>,
      default: () => [],
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  data() {
    return {
      inputs: {
        rows: JSON.parse(JSON.stringify(this.modelValue)),
      },
    };
  },
  watch: {
    modelValue: {
      handler(newModelValue) {
        this.inputs.rows = JSON.parse(JSON.stringify(newModelValue));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    triggerEmit() {
      this.$emit('update:modelValue', this.inputs.rows);
    },
    addRow() {
      const newRow: Record<string, any> = {};
      this.columns.forEach((column) => {
        newRow[column.prop] = '';
      });
      this.inputs.rows.push(newRow);
      this.triggerEmit();
    },
    addRowBelow(index: number) {
      const newRow: Record<string, any> = {};
      this.columns.forEach((column) => {
        newRow[column.prop] = '';
      });
      this.inputs.rows.splice(index, 0, newRow);
      this.triggerEmit();
    },
    removeRow(index: number) {
      this.inputs.rows.splice(index, 1);
      this.triggerEmit();
    },
  },
});
