export const fr = {
  fileUploadUiComponent: {
    formattedMaxFileSize: '{num} Mo',
    maxFileSize: 'Taille maximale du fichier',
    acceptedFileTypes: 'Types de fichiers acceptés',
    fileExceedsLimit: 'Chaque fichier doit être plus petit que {limit}',
    tooManyFiles: 'Vous pouvez télécharger un maximum de {num} fichier(s).',
  },
  calculationQuestion: {
    label: 'Votre réponse',
    dataRequiredError: 'Vous devez taper une réponse.',
    invalidInputError: 'Vous devez taper un nombre valide.',
  },
  dragToRank: {
    availableItems: 'Éléments disponibles à trier',
    dragItemsHere: 'Faites glisser les éléments ici pour commencer le tri',
    outOfItems: "Il ne reste plus d'éléments à trier",
  },
  rankOrderUiComponent: {
    smallest: 'Le plus petit',
    largest: 'Le plus grand',
  },
};
