import Vue from 'vue';
import {TaskCardMixin} from './task-card';
import {MARKING_TASKS} from '@/router/route-names';
import {clone} from '@/common/utils/clone.ts';
import {temporarySettingResponseFlag} from '@/tasks/utils/temporarySettingResponseFlag';

export function DynamicQuestionMixin() {
  return {
    props: {
      task: {},
    },
    mixins: [TaskCardMixin()],
    computed: {
      inputRoot() {
        const inputs = this.inputs || [];
        return inputs instanceof Array ? this : inputs;
      },
      inputRootKeys() {
        const inputs = this.inputs || [];
        return inputs instanceof Array ? inputs : Object.keys(inputs);
      },
      allInputData() {
        const data = {};
        for (const input of this.inputRootKeys) {
          const inputValue = this.inputRoot[input];
          // Convert any undefined input values to null, which aren't stripped before they are sent to the API
          data[input] = typeof inputValue === 'undefined' ? null : inputValue;
        }
        return data;
      },
      // FIXME: remove these once questions are converted since feedback is outside now
      validFeedback() {
        return null;
      },
      invalidFeedback() {
        return null;
      },
      attachmentParts() {
        return {};
      },
      attachments() {
        return [];
      },
      isMarking() {
        return this.$route.name === MARKING_TASKS;
      },
    },
    watch: {
      attachments() {
        if (this._isSettingResponse) {
          return;
        }
        this.emitUpdateInputData();
      },
      allInputData: {
        handler() {
          if (this._isSettingResponse) {
            return;
          }
          this.emitUpdateInputData();
        },
        deep: true,
      },
    },
    methods: {
      setInputValue(key, value) {
        if (this.inputRoot[key] instanceof Array) {
          const length = this.inputRoot[key].length;
          for (let i = 0; i < length; i++) {
            this.inputRoot[key].pop();
          }

          value = value || [];
          for (const item of value) {
            this.inputRoot[key].push(item);
          }
        } else {
          // We want reactivity on the new property since it wasn't already declared reactive
          Vue.set(this.inputRoot, key, value);
        }
      },
      setResponse(response) {
        temporarySettingResponseFlag(
          (v) => (this._isSettingResponse = v),
          () => {
            // We clear existing response data in case the server response is missing properties
            // that have since been added to the `inputs`
            for (const input of this.inputRootKeys) {
              if (this.inputRoot[input] instanceof Array) {
                this.setInputValue(input, []);
              } else {
                this.setInputValue(input, null);
              }
            }

            // We then set the response data, trusting whatever is in the response's `data`
            const data = clone(response?.content?.data || {});
            for (const [k, v] of Object.entries(data)) {
              this.setInputValue(k, v);
            }

            this.clearAttachments();
          }
        );
      },
      clearResponse() {
        this.setResponse(null);
      },
      clearAttachments() {
        const fileKeys = Object.entries(this.$data)
          .filter(
            (v) =>
              v[1] instanceof File ||
              (Array.isArray(v[1]) && v[1].every((innerItem) => innerItem instanceof File))
          )
          .map(([key]) => key);

        fileKeys.forEach((key) => (this[key] = Array.isArray(this[key]) ? [] : null));
      },
      emitUpdateInputData() {
        this.$emit('update:taskAttachments', this.attachments);
        this.$emit('update:inputData', this.getResponsePayload());
      },
      getResponsePayload() {
        return {
          data: this.allInputData,
          attachmentParts: this.attachmentParts,
        };
      },
      submitResponse() {
        this.$emit('submit-response', {response: this.getResponsePayload()});
      },
    },
  };
}

export default DynamicQuestionMixin;
