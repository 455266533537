





























































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab1TLCPlate1V2Fr',
  components: {FileUploadUiComponent, StembleLatex, TableInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rows: [
          {
            plate: '',
            spotLabel: '',
            dToSpot: '',
            dToFront: '',
            rf: '',
          },
        ],
        sampleCalc: null,
      },
      columns: [
        {label: 'Plaque de CCM', component: STextareaShort, prop: 'plate', width: '15%'},
        {
          label: 'Étiquette de la tache',
          component: STextareaShort,
          prop: 'spotLabel',
          width: '15%',
        },
        {
          label: 'Distance de la tache (cm)',
          component: CalculationInput,
          prop: 'dToSpot',
          width: '25%',
        },
        {
          label: 'Distance du front du solvant (cm)',
          component: CalculationInput,
          prop: 'dToFront',
          width: '25%',
        },
        {label: 'Valeur de Rf', component: CalculationInput, prop: 'rf', width: '20%'},
      ],
      TlcPlateA: [] as File[],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.TlcPlateA];
    },
  },
});
