<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Valence bond theory would refer to a covalent bond between two atomic orbitals by
        <stemble-latex :content="bondDescription" />
        overlap of two p-orbitals as a:
      </p>

      <v-radio-group v-model="inputs.input1" class="pl-7" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question243d',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {
          expression: '$\\text{Sigma, }\\sigma\\text{, bond}$',
          value: 'sigma',
        },
        {
          expression: '$\\text{Delta, }\\delta\\text{, bond}$',
          value: 'delta',
        },
        {expression: '$\\text{Ionic bond}$', value: 'ionic'},
        {expression: '$\\text{Pi, }\\pi\\text{, bond}$', value: 'pi'},
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          // 1 = sigma-bond
          return {
            bondDescription: '$\\text{end-to-end}$',
          };
        case 2:
          // 2 = pi-bond
          return {
            bondDescription: '$\\text{side-by-side}$',
          };
        default:
          return {
            bondDescription: '$\\text{\\textcolor{red}{ERROR: Please reload page}}$',
          };
      }
    },
    bondDescription() {
      return this.versionData.bondDescription;
    },
  },
};
</script>
