<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Balance the following redox reaction in <b>basic solution</b> using the half reactions
        method.
      </p>

      <p class="mb-6 pl-10">
        <chemical-latex content="Zn(s) + NO3−(aq) -> Zn^2+(aq) + NH3(aq)" />
      </p>

      <chemical-notation-input
        v-model="inputs.redoxReaction"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question232',
  components: {
    ChemicalLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        redoxReaction: null,
      },
    };
  },
};
</script>
