<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
        (Recalculate actual values based on what is weighed out in lab for in-lab and lab report
        calculations.)
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings" :key="head.text">
              {{ head.text }}
            </th>
            <th>
              Solute to Solvent Ratio (
              <stemble-latex
                content="$\text{V}_{\text{solute}} \text{(ml)}:\text{V}_{\text{solvent}} \text{(ml)}$"
              />
              )
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>L-carvone</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaCarv"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmCarv"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateCarv" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpCarv"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cCarv"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Dihydrocarveol</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaDHC"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmDHC"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateDHC" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpDHC"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cDHC"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Eugenol</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaEu"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmEu"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateEu" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpEu"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cEu"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>(S)-limonene</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaLim"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmLim"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateLim" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpLim"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cLim"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Vanillin</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaVan"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateVan" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Heptane</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaHep"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmHep"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateHep" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dHep"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Ethyl Acetate</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaEtAc"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmEtAc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateEtAc" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dEtAc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Potassium Permanganate</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaKMnO4"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmKMnO4"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateKMnO4" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cKMnO4"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <v-select
        v-model="inputs.equipment"
        :items="equipmentOptions"
        multiple
        chips
        class="ml-6 mb-n4 mt-0"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LDFS1IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaCarv: null,
        mmCarv: null,
        physStateCarv: null,
        mpCarv: null,
        cCarv: null,

        chemFormulaDHC: null,
        mmDHC: null,
        physStateDHC: null,
        mpDHC: null,
        cDHC: null,

        chemFormulaEu: null,
        mmEu: null,
        physStateEu: null,
        mpEu: null,
        cEu: null,

        chemFormulaLim: null,
        mmLim: null,
        physStateLim: null,
        mpLim: null,
        cLim: null,

        chemFormulaVan: null,
        mmVan: null,
        physStateVan: null,
        mpVan: null,
        cVan: null,

        chemFormulaHep: null,
        mmHep: null,
        physStateHep: null,
        dHep: null,

        chemFormulaEtAc: null,
        mmEtAc: null,
        physStateEtAc: null,
        dEtAc: null,

        chemFormulaKMnO4: null,
        mmKMnO4: null,
        physStateKMnO4: null,
        cKMnO4: null,

        equipment: [],
      },
      headings: [
        {text: 'Chemical Name'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Physical State or Solvent (if in nonaqueous solution)'},
        {text: 'Density (g/mL)'},
        {text: 'Melting Point (°C)'},
      ],
      stateOptions: ['l', 's', 'g', 'aq', 'heptane', 'ethyl acetate'],

      equipmentOptions: [
        'beaker(s)',
        'Buchner funnel',
        'buret',
        'capillary/melting tube',
        'clamp and stand',
        'cuvette',
        'Digi-Melt (melting point apparatus)',
        'digital balance',
        'disposable pipet',
        'drying oven',
        'Erlenmeyer flask',
        'filter paper',
        'funnel',
        'graduated cylinder',
        'ice',
        'KimWipes (tissues)',
        'LabQuest',
        'pencil',
        'pipet suction bulb',
        'probe (pH)',
        'probe (temperature)',
        'scintillation vial',
        'side arm flask',
        'spatula or scoopula',
        'spectrometer',
        'spotter',
        'stir bar',
        'stirrer/hotplate',
        'test tube',
        'thermometer (alcohol)',
        'TLC (developing) chamber',
        'TLC plate',
        'TLC spotter',
        'UV lamp',
        'volumetric flask',
        'volumetric pipet',
        'watch glass',
        'weighing boat or paper',
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
