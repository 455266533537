<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        A hydrogen atom absorbs a photon with a wavelength of
        <latex-number :number="wavelength" unit="\text{nm.}" /> Assuming the hydrogen atom started
        in the ground state, which energy level, <stemble-latex content="$n,$" /> would it have been
        excited to upon absorption of this photon?
      </p>

      <calculation-input v-model="inputs.nFinal" prepend-text="$n:$" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question249',
  components: {
    CalculationInput,
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        nFinal: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    wavelength() {
      if (this.versionNumber.value === 1) {
        return '102.6'; // n = 3
      } else if (this.versionNumber.value === 2) {
        return '94.96'; // n = 5
      } else if (this.versionNumber.value === 3) {
        return '93.06'; // n = 7
      } else if (this.versionNumber.value === 4) {
        return '121.5'; // n = 2
      } else if (this.versionNumber.value === 5) {
        return '97.23'; // n = 4
      } else if (this.versionNumber.value === 6) {
        return '93.76'; // n = 6
      } else {
        return 'error';
      }
    },
  },
};
</script>
