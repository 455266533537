<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Recognizing Good Issue Statements</h2>

      <p class="mb-2">
        What are the essential elements of a strong issue statement? Select all that apply.
      </p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox
          v-model="inputs.studentAnswers"
          :value="option.value"
          :label="option.text"
          class="mb-n4 mt-0 pl-6"
        >
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'MarketingScaffoldedTasks_Phase1Task3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'In the form of a question', value: 'questionForm'},
        {text: 'Uses a valid marketing concept', value: 'usesTool'},
        {text: 'Includes the company name spelled correctly', value: 'companyName'},
        {text: 'Proposes a solution', value: 'proposesSolution'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
