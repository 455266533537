<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A
        <number-value :value="volume" unit="\text{L}" />
        vessel at
        <stemble-latex content="$23^\circ\text{C}$" />
        contains three gases:
        <chemical-latex content="N2," />
        <chemical-latex content="H2," /> and
        <chemical-latex content="O2\text{.}" />
        The system contains
        <number-value :value="massN2" unit="\text{g}" />
        of
        <chemical-latex content="N2," />
        <number-value :value="massH2" unit="\text{g}" />
        of
        <chemical-latex content="H2," />
        and
        <number-value :value="massO2" unit="\text{g}" />
        of
        <chemical-latex content="O2\text{.}" />
      </p>

      <p class="mb-2">a) Determine the moles of each gas in the reaction vessel.</p>

      <calculation-input
        v-model="inputs.molN2"
        class="mb-2"
        prepend-text="$\ce{N2}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.molH2"
        class="mb-2"
        prepend-text="$\ce{H2}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.molO2"
        class="mb-5"
        prepend-text="$\ce{O2}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) Determine the total pressure in the container (in atm).</p>
      <calculation-input
        v-model="inputs.Ptot"
        class="mb-3"
        prepend-text="$\text{P}_\text{tot}:$"
        append-text="$\text{atm}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question245',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molN2: null,
        molH2: null,
        molO2: null,
        Ptot: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    massN2() {
      return this.taskState.getValueBySymbol('massN2').content;
    },
    massH2() {
      return this.taskState.getValueBySymbol('massH2').content;
    },
    massO2() {
      return this.taskState.getValueBySymbol('massO2').content;
    },
  },
};
</script>
