








































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'UCIrvineELN2v2DataAnalysis1',
  components: {FileUploadUiComponent, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        dataAnalysis1Link: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: 'Please paste a link to reference your data file or Google sheet',
          inputName: 'dataAnalysis1Link',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
