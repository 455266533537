import { render, staticRenderFns } from "./McMaster1A03Lab1Results.vue?vue&type=template&id=aefacb22&"
import script from "./McMaster1A03Lab1Results.vue?vue&type=script&lang=js&"
export * from "./McMaster1A03Lab1Results.vue?vue&type=script&lang=js&"
import style0 from "./McMaster1A03Lab1Results.vue?vue&type=style&index=0&id=aefacb22&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSelect,VSimpleTable})
