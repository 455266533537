<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Using Slater's rules, determine the effective nuclear charge,
        <stemble-latex content="$Z_\text{eff},$" /> for:
      </p>

      <p>
        a) One of the <span class="text-bold">outermost</span> electrons in a
        <chemical-latex :content="atomName" /> atom
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-8"
        prepend-text="$Z_\text{eff}:$"
        :disabled="!allowEditing"
      />

      <p>
        b) An electron in the <span class="text-bold">1s orbital</span> of
        <chemical-latex :content="atomName" />
      </p>

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$Z_\text{eff}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question206',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('element1').content;
    },
    atomName() {
      return `\\ce{${this.chemicalElement.name}}`.toLowerCase();
    },
  },
};
</script>
