<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The graph shown below is depicting the concentration of reactant
        <stemble-latex content="$\text{A}$" />
        over the course of a hypothetical
        <span class="text-bold text-danger">first-order</span>
        reaction.
      </p>

      <p>
        <span style="color: #a2a2a2"
          >Note: You can use your mouse on the graph to help you determine the numbers.</span
        >
      </p>

      <p class="mb-3">
        <apex-chart
          type="area"
          height="400"
          width="600"
          :options="kineticsPlotOptions"
          :series="kineticsData"
        />
      </p>

      <p class="mb-2 pl-2">
        a) What is the half-life,
        <stemble-latex content="$\text{t}_{1/2},$" />
        for the reaction in hours?
      </p>
      <calculation-input
        v-model="inputs.t12"
        prepend-text="$\text{t}_{1/2}:$"
        append-text="$\text{h}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-2 pl-2">
        b) Remembering that this is a first-order reaction, what is the rate constant,
        <stemble-latex content="$\text{k},$" />
        for the reaction?
      </p>
      <calculation-input
        v-model="inputs.rateConstant"
        prepend-text="$\text{k:}$"
        append-text="$\text{h}^{-1}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-2 pl-2">
        c) Based on the initial concentration shown in the graph, how long would it take (in hours)
        until only
        <number-value :value="concA" unit="\text{M}" />
        of
        <stemble-latex content="$\text{A}$" />
        is remaining?
      </p>
      <calculation-input
        v-model="inputs.reactionTime"
        prepend-text="$\text{t:}$"
        append-text="$\text{h}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import {linspace} from '../../utils/math';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';

export default {
  name: 'Question169',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      kineticsPlotOptionsStatic: {
        chart: {
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          stroke: {
            curve: 'straight',
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#8B0000', '#474747'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: '#c9c9c9',
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
        },
        yaxis: {
          title: {
            text: '[A] (M)',
            style: {
              color: undefined,
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            style: {
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          labels: {
            formatter(val) {
              return val.toFixed(2);
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          tickAmount: 10,
          tickPlacement: 'between',
          min: 0,
          max: 10,
        },
        xaxis: {
          title: {
            text: 'Time (h)',
            style: {
              color: undefined,
              fontSize: '16px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
          labels: {
            formatter(val) {
              return val.toFixed(2);
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Times New Roman',
              fontWeight: 600,
            },
          },
          tickAmount: 10,
          tickPlacement: 'between',
          min: 0,
          max: 5,
        },
      },
      inputs: {
        t12: null,
        rateConstant: null,
        reactionTime: null,
      },
    };
  },

  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    proxyRateConstant() {
      return this.taskState.getValueBySymbol('proxyRateConstant').content;
    },
    xValues() {
      return linspace(0, 5, 500);
    },
    kineticsFunction() {
      // The proxy value / 200 is just so that student's can't look at the task state in the server's response
      return (t) => 10 * Math.exp((-this.proxyRateConstant.number / 200) * t);
    },
    kineticsData() {
      const data = this.xValues.map((t) => [t, this.kineticsFunction(t)]);
      return [
        {
          name: '1',
          data,
        },
      ];
    },
    kineticsPlotOptions() {
      return this.kineticsPlotOptionsStatic;
    },
  },
};
</script>
