<template>
  <div>
    <v-container>
      <v-row class="mb-4">
        <h2>Information du Départ</h2>
      </v-row>

      <v-simple-table>
        <thead>
          <tr v-for="field in fields" :key="field.inputKey">
            <td style="width: 220px">
              {{ field.name }}
            </td>
            <td>
              <v-text-field v-model="inputs[field.inputKey]" />
            </td>
          </tr>
          <tr>
            <td style="width: 220px">J'ai écrit ce rapport:</td>
            <td>
              <v-select
                v-model="inputs.hasPartner"
                :items="partnerOptions"
                single
                chips
                class="ml-6 mb-n4 mt-0"
              >
              </v-select>
            </td>
          </tr>
          <tr v-if="inputs.hasPartner === 'avec mon partenaire'">
            <td style="width: 220px">Nom du partenaire:</td>
            <td>
              <v-text-field v-model="inputs.partner" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'uOttawa1321Introduction',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        title: null,
        author: null,
        partner: null,
        demonstrator: null,
        date: null,
        hasPartner: null,
      },
      fields: [
        {name: "Titre de l'expérience: ", inputKey: 'title'},
        {name: "Nom(s) d(es)'auteur(s): ", inputKey: 'author'},
        {name: 'Nom du TA (démonstrateur): ', inputKey: 'demonstrator'},
        {name: "Date que l'expérience a été effectueéé: ", inputKey: 'date'},
      ],
      partnerOptions: ['seul(e)', 'avec mon partenaire'],
    };
  },
};
</script>

<style scoped></style>
