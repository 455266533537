<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The density of liquid bromine is
        <stemble-latex content="$3.12\,\text{g/mL.}$" />
        What is the mass of
        <number-value :value="volume" unit="\text{L}" />
        of bromine (in g)?
      </p>
      <calculation-input
        v-model="inputs.input1"
        prepend-text="Mass:"
        append-text="g"
        class="mb-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question189',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
