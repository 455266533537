var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Enter appropriate identifying information for each chemical used during Fundamental Skills. (Recalculate actual values based on what is weighed out in lab for in-lab and lab report calculations.) ")]),_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.headings),function(head){return _c('th',{key:head.text},[_vm._v(" "+_vm._s(head.text)+" ")])}),_c('th',[_vm._v(" Solute to Solvent Ratio ( "),_c('stemble-latex',{attrs:{"content":"$\\text{V}_{\\text{solute}} \\text{(ml)}:\\text{V}_{\\text{solvent}} \\text{(ml)}$"}}),_vm._v(" ) ")],1)],2)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("L-carvone")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaCarv),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaCarv", $$v)},expression:"inputs.chemFormulaCarv"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmCarv),callback:function ($$v) {_vm.$set(_vm.inputs, "mmCarv", $$v)},expression:"inputs.mmCarv"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateCarv),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateCarv", $$v)},expression:"inputs.physStateCarv"}})],1),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpCarv),callback:function ($$v) {_vm.$set(_vm.inputs, "mpCarv", $$v)},expression:"inputs.mpCarv"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cCarv),callback:function ($$v) {_vm.$set(_vm.inputs, "cCarv", $$v)},expression:"inputs.cCarv"}})],1)]),_c('tr',[_c('td',[_vm._v("Dihydrocarveol")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaDHC),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaDHC", $$v)},expression:"inputs.chemFormulaDHC"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmDHC),callback:function ($$v) {_vm.$set(_vm.inputs, "mmDHC", $$v)},expression:"inputs.mmDHC"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateDHC),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateDHC", $$v)},expression:"inputs.physStateDHC"}})],1),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpDHC),callback:function ($$v) {_vm.$set(_vm.inputs, "mpDHC", $$v)},expression:"inputs.mpDHC"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cDHC),callback:function ($$v) {_vm.$set(_vm.inputs, "cDHC", $$v)},expression:"inputs.cDHC"}})],1)]),_c('tr',[_c('td',[_vm._v("Eugenol")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaEu),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaEu", $$v)},expression:"inputs.chemFormulaEu"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmEu),callback:function ($$v) {_vm.$set(_vm.inputs, "mmEu", $$v)},expression:"inputs.mmEu"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateEu),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateEu", $$v)},expression:"inputs.physStateEu"}})],1),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpEu),callback:function ($$v) {_vm.$set(_vm.inputs, "mpEu", $$v)},expression:"inputs.mpEu"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cEu),callback:function ($$v) {_vm.$set(_vm.inputs, "cEu", $$v)},expression:"inputs.cEu"}})],1)]),_c('tr',[_c('td',[_vm._v("(S)-limonene")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaLim),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaLim", $$v)},expression:"inputs.chemFormulaLim"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmLim),callback:function ($$v) {_vm.$set(_vm.inputs, "mmLim", $$v)},expression:"inputs.mmLim"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateLim),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateLim", $$v)},expression:"inputs.physStateLim"}})],1),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpLim),callback:function ($$v) {_vm.$set(_vm.inputs, "mpLim", $$v)},expression:"inputs.mpLim"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cLim),callback:function ($$v) {_vm.$set(_vm.inputs, "cLim", $$v)},expression:"inputs.cLim"}})],1)]),_c('tr',[_c('td',[_vm._v("Vanillin")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaVan),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaVan", $$v)},expression:"inputs.chemFormulaVan"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmVan),callback:function ($$v) {_vm.$set(_vm.inputs, "mmVan", $$v)},expression:"inputs.mmVan"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateVan),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateVan", $$v)},expression:"inputs.physStateVan"}})],1),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpVan),callback:function ($$v) {_vm.$set(_vm.inputs, "mpVan", $$v)},expression:"inputs.mpVan"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cVan),callback:function ($$v) {_vm.$set(_vm.inputs, "cVan", $$v)},expression:"inputs.cVan"}})],1)]),_c('tr',[_c('td',[_vm._v("Heptane")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaHep),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaHep", $$v)},expression:"inputs.chemFormulaHep"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmHep),callback:function ($$v) {_vm.$set(_vm.inputs, "mmHep", $$v)},expression:"inputs.mmHep"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateHep),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateHep", $$v)},expression:"inputs.physStateHep"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.dHep),callback:function ($$v) {_vm.$set(_vm.inputs, "dHep", $$v)},expression:"inputs.dHep"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Ethyl Acetate")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaEtAc),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaEtAc", $$v)},expression:"inputs.chemFormulaEtAc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmEtAc),callback:function ($$v) {_vm.$set(_vm.inputs, "mmEtAc", $$v)},expression:"inputs.mmEtAc"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateEtAc),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateEtAc", $$v)},expression:"inputs.physStateEtAc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.dEtAc),callback:function ($$v) {_vm.$set(_vm.inputs, "dEtAc", $$v)},expression:"inputs.dEtAc"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Potassium Permanganate")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaKMnO4),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaKMnO4", $$v)},expression:"inputs.chemFormulaKMnO4"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmKMnO4),callback:function ($$v) {_vm.$set(_vm.inputs, "mmKMnO4", $$v)},expression:"inputs.mmKMnO4"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateKMnO4),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateKMnO4", $$v)},expression:"inputs.physStateKMnO4"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cKMnO4),callback:function ($$v) {_vm.$set(_vm.inputs, "cKMnO4", $$v)},expression:"inputs.cKMnO4"}})],1)])])]),_c('p',{staticClass:"mt-5"},[_vm._v("Select all the glassware/equipment/instrumentation used in this lab.")]),_c('v-select',{staticClass:"ml-6 mb-n4 mt-0",attrs:{"items":_vm.equipmentOptions,"multiple":"","chips":""},model:{value:(_vm.inputs.equipment),callback:function ($$v) {_vm.$set(_vm.inputs, "equipment", $$v)},expression:"inputs.equipment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }