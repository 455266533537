<template>
  <div>
    <p class="mb-0">
      <chemical-latex content="\ce{Be(OH)2}" />
      is one of the few group 2 metal hydroxides that is not a strong base due to its poor
      solubility. It has a
      <stemble-latex content="$\text{K}_{\text{sp}}$" />
      value of
      <stemble-latex content="$6.92\times 10^{-22}.$" />
      Which one of the following compounds would
      <span class="text-bold text-danger">decrease</span>
      the solubility of
      <chemical-latex content="\ce{Be(OH)2}" />
      if added to the same solution?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question170c',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: '$\\ce{HCl}$', value: 'HCl'},
        {expression: '$\\ce{BeCl2}$', value: 'BeCl2'},
        {expression: '$\\ce{NaCl}$', value: 'NaCl'},
        {expression: '$\\ce{HBr}$', value: 'HBr'},
        {expression: '$\\ce{Be(OH)2}$', value: 'BeOH2'},
      ],
    };
  },
};
</script>
