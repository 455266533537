import {GradeStatus} from '@/assignments/types/GradeStatus';
import {GradingStatus} from '@/assignments/types/GradingStatus';
import {courseWizard} from '@/locales/en/courseWizard';
import {courseSettings} from '@/locales/en/courseSettings';
import {institutionSelector} from '@/locales/en/institutionSelector';
import {institutionEditor} from '@/locales/en/institutionEditor';
import {ConsentCategory} from '@/cookie-consent/types/ConsentCategory';
import {userSelector} from '@/locales/en/userSelector';
import {simulations} from '@/locales/en/simulations';

export const en = {
  atom: 'Atom',
  days: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  active: 'Active',
  'mark-as-active': 'Mark role as active',
  beta: 'BETA',
  'import-csv': 'Import CSV',
  'export-csv': 'Export CSV',
  calendar: 'Calendar',
  done: 'Done',
  multiSelectSelectionOverflow: '+ {count} More',
  components: 'Components',
  dashboardPage: {
    paymentSuccessful:
      'Your payment was successful. If you cannot see your course, please contact support at',
    ariaLabels: {
      timeline: 'navigate to this timeline item',
    },
    title: 'Dashboard',
    tooltips: {
      timeline: 'Click Here to navigate to the timeline item',
    },
    freeTrialEnding: 'Free Trial Ending',
    welcome: {
      title: 'Welcome to Stemble!',
      message: `We are pleased to have you on board this semester. If you have any feedback or
          experience any issues, don't hesitate to contact us at support@stemble.com.`,
    },
  },
  paymentAlert: {
    trialAccessExpired: 'Uh oh! Your free trial access to {course} has expired!',
    trialAccessExpiring: 'Uh oh! Your free trial access to {course} will soon expire on {dueDate}!',
    missingOut: 'You might be missing out on important assignments.',
    renew: 'You can renew your subscription by going to the billing page.',
    goToBilling: 'Go To Billing',
  },
  appBar: {
    iconAriaLabels: {
      settings: 'settings menu',
      notifications: 'notifications menu',
      account: 'account details menu',
      support: 'support',
    },
  },
  dragToRank: {
    availableItems: 'Available Items to Sort',
    dragItemsHere: 'Drag your items here to start ranking',
    outOfItems: 'There are no more items left to sort',
  },
  dragToRankFr: {
    availableItems: 'Articles disponibles à trier',
    dragItemsHere: 'Faites glisser vos articles ici pour commencer le tri',
    outOfItems: "Il ne reste plus d'articles à trier",
  },
  dashboard: 'Dashboard',
  'my-profile': 'My Profile',
  'living-syllabus': 'Living Syllabus',
  english: 'English',
  french: 'French',
  chemicalEquationInput: {
    headers: {
      answer: 'Your Answer: ',
      create: 'Create a Chemical Species',
      holding: 'Chemical Species Holding Area',
      reactants: 'Reactants',
      products: 'Products',
    },
    labels: {
      input: 'Type your chemical species and hit enter',
      holding: 'Create a species above to populate your chemical species area',
      reactants: 'Drag a chemical species here to populate your reactants',
      products: 'Drag a chemical species here to populate your products',
      species: 'Species:',
    },
    error: {
      state:
        "It looks like you haven't indicated the state of your species properly. The acceptable options are: (l) (g) (aq) (s)",
    },
  },
  tableInput: {
    step: 'Step',
    headerPlaceholder: 'Enter Column Title',
    addRow: 'Add Row',
    addColumn: 'Add Column',
    deleteRow: 'Delete this Row?',
    deleteColumn: 'Delete this Column?',
    newColumn: 'New Column',
  },
  calculationInput: {
    helpfulTip:
      '<b>Helpful tip:</b> To enter a number in scientific notation, enter 1.0E5 or 1.0e5, for 1.0 x 10<sup>5</sup> where the E# (or e#) denotes 10<sup>#</sup>',
  },
  chemicalNotationInput: {
    answer: 'Your Answer: ',
    placeholder: 'Enter your chemical notation here',
    pleaseNote: 'Please Note:',
    caseSensitive: 'Chemical notation is case sensitive',
  },
  userProfile: {
    purchaseActivate: 'Purchase/Activate',
    paid: 'Paid',
    unpaid: 'Unpaid',
    noPaymentRequired: 'No payment required',
    setAs: 'Set as {0}',
    confirmText: 'Set student as "{0}" in "{1}" course?',
    exempt: 'Exempt',
    required: 'Payment Required',
    remove: 'Remove',
  },
  payments: {
    howWouldYouLikeToPay: 'How would you like to pay?',
    iHaveAccessCode: 'I have an access code',
    payOnline: 'Pay Online',
    payWithAccessCode: 'Pay with access code',
    accessCode: 'Access Code',
    alreadyLinked: 'This access code has already been claimed.',
    notForCourse: 'This access code is not valid for this course.',
    invalidCode: 'This access code is not valid.',
  },
  incompatibleMessages: {
    buttons: {
      downloadChrome: 'Download Chrome',
      close: 'Close',
    },
    platformHeader: 'Device Not Compatible',
    browserHeader: 'Browser Not Compatible',
    platform:
      "It looks like you're using a mobile or tablet device. Stemble simulations are only compatible on desktop, Mac, or laptop computers. Please try again on a compatible device.",
    browser:
      "It looks like you're using a browser that Stemble isn't optimized for. Stemble simulations are best experienced with Mozilla Firefox, Google Chrome or Microsoft Edge. If you do not have any of the these browsers, please click below to download chrome",
  },
  'no-notifications': 'You have no notifications.',
  'payment-required':
    'You have courses that require payment. Please visit your profile to begin the payment process.',
  'my-courses': 'My Courses',
  'my-calendar': 'My Calendar',
  'my-grades': 'My Grades',
  'edit-profile': 'Edit Profile',
  editAssignmentButton: 'Edit Assignment',
  register: 'Register Page',
  search: 'Search',
  settings: 'Settings',
  tables: 'Tables',
  tabs: 'Tabs',
  stemble: 'Stemble',
  timeline: 'Timeline',
  none: 'None',
  user: 'User Profile',
  introduction: 'Introduction',
  'grade-table': 'Grade Table',
  participants: 'Participants',
  staffroom: 'Staffroom',
  analytics: 'Analytics',
  assignmentGradeOverride: {
    action: 'Override Grade',
    detailsDescription: 'Grade adjusted by {name}:',
    originalGrade: 'Original Grade',
    newGrade: 'New Grade',
    adjustment: 'Adjustment',
    fallbackReason: 'No further information about the adjustment was provided.',
    tooltip: 'This grade includes an adjustment, click for more details.',
  },
  analyticsPage: {
    stemblytics: 'Stemblytics',
    totalSessions: 'Sessions',
    sessionDuration: 'Session Duration',
    sessionsByDate: 'Sessions by Date',
    tooltips: {
      refreshData: 'Click here to refresh data',
    },
    dateRangeLabels: {
      last7: 'Last 7 Days',
      last28: 'Last 28 Days',
      last90: 'Last 90 Days',
      custom: 'Choose Custom Dates...',
    },
    buildingHeatmap: 'Building Heatmap',
    fetchingData: 'Fetching Data',
    findingStudents: 'Finding Students',
    whereAreStudents: 'Where are your students?',
    whenAreStudents: 'When are your students active?',
    reports: {
      deviceBreakdown: {
        usersByDeviceBreakdown: 'Users by device breakdown',
        numUsers: 'Num Users',
        deviceType: 'Device Type',
        usersByDeviceType: 'UsersByDeviceType',
      },
      usersByLocation: {
        users: 'Users',
        country: 'Country',
        usersByCountry: 'UsersByCountry',
      },
    },
  },
  'sidebar-mini': 'Sidebar Mini',
  'dark-mode': 'Dark Mode',
  'view-profile': 'View Profile',
  'change-password': 'Change Password',
  'log-out': 'Log Out',
  'register-access': 'Register To Get Instant Access',
  'required-field': '(All Fields Required)',
  'i-agree': 'I agree to the',
  'terms-cond': 'Terms and Conditions',
  'create-acc': 'Create Account',
  'have-account': 'Already have an account?',
  'login-now': 'Back to Login',
  'invalid-login': 'Invalid username or password',
  'rate-limit-hit': 'Too many failed attempts. Please try again later.',
  'first-access': 'First Access',
  'last-access': 'Last Access',
  home: 'Home',
  assignment: 'Assignment',
  overview: 'Overview',
  'course-overview': 'Course Overview',
  'go-to-assignment': 'Go to Assignment',
  participant: 'Participant',
  sections: 'Sections',
  assignments: 'Assignments',
  'course-materials': 'Course Materials',
  'add-course-materials': 'Add Course Materials',
  'course-name': 'Course Name',
  title: 'Title',
  link: 'Link',
  'course-schedule': 'Course Schedule',
  view: 'View',
  remove: 'Remove',
  save: 'Save',
  cancel: 'Cancel',
  staff: 'Staff',
  duration: 'Duration',
  name: 'Name',
  role: 'Role',
  email: 'Email',
  reset: 'Reset',
  students: 'Students',
  completed: 'Completed',
  'in-progress': 'In Progress',
  submitted: 'Submitted',
  due: 'Due',
  performance: 'Performance',
  announcement: 'Announcement',
  'more-info': 'More Info',
  'assignment-overview': 'Assignment Overview',
  'see-all-assignments': 'See All Assignments',
  allAssignments: 'All Assignments',
  assignmentOverviewText: "You'll find all of your assignments for {courseName} here",
  'have-not-started': 'Have Not Started',
  'grade-is-locked': 'Grade is not yet visible',
  started: 'Started',
  graded: 'Graded',
  question: 'Question',
  taskId: 'Task ID',
  'go-back': 'Go Back',
  'back-to-the-first-question': 'Back to the first task',
  'submit-response': 'Submit Response',
  'select-task': 'Select Task',
  'submit-all': 'Submit All',
  'assignment-info': 'Assignment Info',
  'quiz-navigation': 'Task Navigation',
  'question-bank': 'Tasks',
  post: 'Post',
  'send-for-review': 'Send For Review',
  release: 'Release',
  submit: 'Submit',
  'next-question': 'Next Task',
  edit: 'Edit',
  feedback: 'Feedback',
  course: 'Course',
  to: 'To',
  from: 'From',
  subject: 'Subject',
  send: 'Send',
  password: 'Password',
  'remember-me': 'Remember Me',
  login: 'Login',
  'dont-have-an-account': " Don't Have an Account?",
  'register-now': 'Register Now',
  'first-name': 'First Name',
  'last-name': 'Last Name',
  'phone-number': 'Phone Number',
  'retype-password': 'Retype Password',
  'password-reset': 'Password Reset',
  'reset-pass': 'Enter your email address to receive instructions on how to reset your password.',
  chooseNewPassword: 'Enter your email address and a new password.',
  invalidReset: {
    title: 'Invalid Credentials',
    text: "Something went wrong. Check your email and that the password reset link you are using isn't too old. You can request a new one by visiting this page from the login.",
  },
  today: 'Today',
  yesterday: 'Yesterday',
  'add-an-event': 'Add an Event',
  close: 'Close',
  'add-note': 'Add Note',
  'search...': 'Search ...',
  'event-type': 'Event Type',
  repeat: 'Repeat',
  'start-date': 'Started',
  'start-time': 'Start Time',
  'end-date': 'End Date',
  'end-time': 'End Time',
  studentInformation: {
    lastLogin: 'Last Login',
    recentAssessments: 'Recent Assessments',
    viewRecentActivity: 'View all recent activity',
    viewAllGrades: 'View all grades',
    studentID: 'Student ID',
    email: 'Email',
    courses: 'Stemble Courses',
  },
  location: 'Location',
  section: 'Section',
  note: 'Note',
  goTo: 'go to ',
  'no-page-text': "The page you are looking for doesn't seem to exist.",
  'go-to-home-page': 'Go to Dashboard',
  'go-to-login-page': 'Go to Login',
  'uh-oh': 'Uh oh...',
  roles: 'Roles',
  assign: 'Assign',
  permissions: 'Permissions',
  'old-password': 'Old Password',
  'new-password': 'New Password',
  'retype-new-password': 'Retype New Password',
  'delete-from-bank': 'Delete selected From Bank',
  'add-assignments': 'Add To The Assignment',
  'teaching-materials': 'Teaching Materials',
  'add-teaching-materials': 'Add Teaching Materials',
  'full-access': 'Full Platform Access',
  'assignment-access': 'Participate In Assignments',
  'grade-access': 'Grade The Courses',
  'only-view': 'Only View Courses',
  'some-access': 'Create/Edit/Add/Remove In All Parts',
  'final-score-access': 'Release The Final Score',
  weight: 'Weight',
  'due-date': 'Due On',
  'no-started': 'Not Started',
  'delete-selected': ' Delete Selected',
  'enrol-participants': 'Enrol Participants',
  'add-section': 'Add Section',
  'remove-section': 'Remove Section',
  'assign-role': 'Assign Role',
  users: 'Users',
  'add-users': 'Add Users',
  'add-users-with-csv': 'Add Users With CSV',
  'user-email-addresses': 'User Email Addresses',
  'search-user-email-addresses':
    'Type to search for existing users, or type an email to add a new user',
  'use-Google-location': "Use Google's location service?",
  disagree: 'Disagree',
  agree: 'Agree',
  ok: 'Ok',
  status: 'Status',
  'search-participants': 'Search Participants',
  'search-participants-placeholder': 'Start typing to Search',
  'add-announcement': ' Add Announcement',
  'publish-data': 'Publish Data',
  'expiration-data': 'Expiration Data',
  'post-now': 'Post Now',
  'publish-date': 'Publish Date',
  'select-publish-date': 'Select Publish Date',
  'select-publish-time': 'Select Publish Time',
  'select-expiration-date': 'Select Expiration Date',
  'select-expiration-time': 'Select Expiration Time',
  questions: 'Tasks',
  'question-weight': 'Weight',
  'question-number': 'Number',
  viewAssignmentExtensions: 'View Assignment Extensions',
  'assignment-overview-overview': 'Overview',
  attempt: 'Attempt',
  'geaded-attempts': 'Complete Graded Attempts',
  'median-grade': 'Median Grade',
  'stand-deviation': 'Standard Deviation',
  'score-distribution-skewness': 'Score Distribution Skewness',
  'avg-grade': 'Average Grade',
  'score.distribution-kurosis': 'Score Distribution Kurtosis',
  total: 'Total',
  id: 'ID',
  'student-name': 'Student Name',
  'student-id': 'Student ID',
  'course-total': 'Course Total',
  'add-assignment': 'Add Assignment',
  'assignment-stat-rpt': 'Assignment Statistics Report',
  extension: 'Extension',
  extensionsFor: 'Extensions for ',
  extensionToolTip:
    "You've been granted an extension for this assignment. Your modified date is indicated.",
  'submission-dte': 'Submission Date',
  grade: 'Grade',
  grades: 'Grades',
  'grade-item': 'Grade Item',
  'my-assignments': 'My Assignments',
  extensions: 'Extensions',
  'original-start-date': 'Original Start Date',
  'new-start-date': 'Modified Start Date',
  'orginal-due-date': 'Original Due Date',
  'new-due-date': 'Modified Due Date',
  number: 'Number',
  'day-week': 'Day/Week',
  current: 'Active',
  newDate: 'New Date',
  'manage-permissions': 'Manage Permissions',
  'sign-out': 'Sign Out',
  'r-u-sure': 'Are You Sure?',
  'sing-out-text':
    "Signing out will end your current session with Stemble. Click 'Sign out' to end your session and continue on to the signin screen, or 'Cancel' to go back.",
  'remove-selected': 'Remove Selected',
  'preview-assignment': 'Preview Assignment',
  'start-assignment': 'Go to Assignment',
  'continue-saved': 'Continue The Saved One',
  'assigned-sections': 'Assigned Sections',
  'view-all-sub': 'View All Submission',
  'question-type': 'Type(s)',
  'grading-method': 'Grading Method',
  'sync-to-lms': 'Sync To LMS',
  'new-assignment': 'New Assignment',
  'submit-assignment': 'Submit Assignment',
  details: 'Details',
  range: 'Range',
  date: 'Date',
  penalty: 'Penalty Per Attempt',
  assignmentExtended: 'Assignment Extended',
  extendedDueDateMessage: 'Your due date for this assignment has been modified!',
  'attempts-allowed': 'Attempts Allowed',
  nOthers: '{n} others',
  resourses: 'Resources',
  drawer: {
    assignments: 'Assignments',
    'grade-table': 'Grade Table',
    courseHome: 'Course Home',
    participants: 'Participants',
    'staff-room': 'Staff Room',
    analytics: 'Analytics',
    pastCourses: 'Past Courses',
    courseSettings: 'Settings',
    createCourse: 'Create Course',
    createTask: 'Create Task',
  },
  inlineDateTimeEditor: {
    at: ' at ',
    tooltips: {
      editDate: 'Click here to edit the date',
      editTime: 'Click here to edit the time',
    },
    time: 'Time',
    date: 'Date',
  },
  update: 'Update',
  addExtensions: {
    quickFilterLabel: 'Search Names, Emails, and Sections',
    loadingExtensions: 'Please wait while we get your extensions',
    noExtensions: 'This assignment has no associated extensions',
    grantExtension: 'Grant Extension',
    newDueDate: 'New Due Date',
    addExtensionButton: 'Add Extension',
    updateExtension: 'Update Extension',
    updateExtensionFor: 'Update Extension For: ',
    extendDueDateBy: 'Extend Due Date By: ',
    dueDatePast: 'Due date cannot be in the past',
    tooltips: {
      editDate: 'Click here to edit the extension date',
      editTime: 'Click here to edit the extension time',
      editExtension: 'Click here to edit this extension',
    },
  },
  chips: {
    addManual: 'Add User',
    addCSV: 'Bulk Update Users',
    lms: 'Sync to LMS',
  },
  addTasks: {
    filterByTitle: 'Search for tasks on any topic',
    filterByTaskType: 'Filter by task type',
    addTaskButton: 'Add Selected Tasks to Assignment',
    searchTopicOntology: 'Search Topics',
  },
  formValidation: {
    alpha: 'Name must only contain alphabetic characters',
    firstName:
      'Names must only contain alphabetic characters, hyphens, or spaces, and not terminate with a hyphen.',
    lastName:
      'Names must only contain alphabetic characters, hyphens, or spaces, and not terminate with a hyphen.',
    phoneNumber: 'Phone number must be in a valid format 123-456-7890, 123 456 7890, or 1234567890',
    confirmPassword: 'Both password and confirm password must match',
    email: 'This is not a valid email',
    min: 'This field must be at least {count} chars',
    minValue: 'Value must be {min} or larger',
    exclusiveMinValue: 'Value must be larger than {min}',
    max: 'This field must be no more than {count} chars',
    maxValue: 'Value must be {max} or smaller',
    passwordRegex:
      'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number',
    required: 'This field is required',
    calculationInput: {
      invalidInput: 'This is not a valid number.',
      trailingE: 'When using scientific notation, you must provide an exponent even if it is 1.',
      outOfRange: 'Must be 1-4',
    },
    integer: 'Value must be an integer.',
    exclusiveBetween: 'Value must be greater than {min} and less than {max}',
    date: 'Value must be a date in YYYY-MM-DD format',
    dateRange: 'Value must be a date range',
  },
  UserCourseStatuses: {
    no: 'Inactive',
    partialRegistered: 'Partially Registered',
    fullyRegistered: 'Fully Registered',
  },
  verifyEmail: {
    codePlaceholder: 'Enter your code here',
    message: 'Please check your inbox for a verification email and follow the instructions',
    verificationCode: 'HAVE A VERIFICATION CODE?',
    resendEmail: 'RESEND VERIFICATION EMAIL',
  },
  'search-event': 'Search for an event...',
  'edit-note': 'Edit Note',
  message: 'Message',
  audience: 'Audience',
  attachment: 'Attachment',
  'total-Avg': 'Total (Avg.)',
  'no-change-quiz': 'Once you submit this assignment, you can NOT change it anymore!',
  'has-not-taken': 'Has Not Taken',
  responsed: 'Responsed',
  wrong: 'Wrong',
  correct: 'Correct',
  oldDueDate: 'Original Due Date',
  extensionGrantedOn: 'Granted On',
  extensionGrantedBy: 'Granted By',
  state: 'State',
  'total-score': 'Total Score',
  'penalty-attemempt': 'Penalty Per Attempt',
  'participant-student': 'Participant Students',
  'time-taken': 'Time Taken',
  'ur-answer': 'Your Answer',
  'enter-mark': 'Enter Mark',
  'only-num': 'Only Number',
  'max-characters': 'Maximum 1200 characters',
  assignmentPage: {
    loseAccessDialog: {
      title: 'You may lose access',
      content:
        'You are about to remove all sections from this assignment that you have access to.  You may no longer be able to see the assignment after completing this operation.  If you require access again you will need to request it from an instructor/admin in the owning section or course.',
    },
    deleteDialog: {
      title: 'Are you sure?',
      text: 'Once an assignment has been deleted, it cannot be recovered.',
      confirm: 'Delete Assignment',
      cancel: 'Cancel',
    },
    buttons: {
      addTask: 'Add New Task',
      close: 'Close',
      save: 'Save',
      gradeTable: 'View Submissions',
      extensions: 'Extensions',
      delete: 'Delete Assignment',
      deleteTooltip: 'Assignment cannot be deleted because at least one person has started it',
    },
    placeholders: {
      assignCourse: 'Assignment is Assigned to ',
      assignedSections: 'Choose sections for this assignment',
    },
    assignCourseLikesTable: {
      header: 'Courses and Sections for ',
      editPolicies: 'Edit Policies',
      assignmentPolicies: 'Assignment Policies',
      editAllTitle: {
        chooseDate: 'Choose a date and time',
        choosePointValue: 'Choose a point value',
        visibleDate: 'Edit All Visible Dates',
        dueDate: 'Edit All Due Dates',
        startDate: 'Edit All Start Dates',
        pointValue: 'Edit All PointValues',
        quizModeReleaseDate: 'Edit All Release Dates',
      },
      editAllButton: 'Edit All',
      headers: {
        grade: 'Assignment Grade',
        visibleDate: 'Visible Date',
        dueDate: 'Due On',
        startDate: 'Started',
        assignedTo: 'Assigned to',
        courseLike: 'Section/Course',
        pointValue: 'Weight',
        quizMode: 'Quiz Mode',
        quizModeReleaseDate: 'Grade Release Date',
        policies: 'Policies',
        assignmentOwner: 'Belongs to',
      },
    },
    tooltips: {
      addTask: 'Click here to add a new task',
      removeSelected: 'Click here to remove all selected tasks',
      previewAssignment: 'Click here to preview this assignment',
      editWeight: 'Click here to edit task weight',
    },
    saveAssignment: 'Save Assignment',
    'video-will-auto-submit': 'Your response will be saved when you watch the video',
    notGradedThroughStemble: 'This task is not graded through Stemble',
    alreadySubmitted:
      'This response has already been submitted. You can modify it to start a new draft.',
    savedAtTooltip: 'Last Saved {savedAt}',
    tasksAttempted: 'tasks attempted',
    noEditPermission: 'You do not have permission to edit the content of this assignment',
  },
  participantsPage: {
    sendInvitations: 'Send Invitations',
    sendInvitationsConfirmation: 'Are you sure? You cannot revoke invitations once they are sent.',
  },
  taskGalleryTable: {
    title: 'Title',
    'question-type': 'Type(s)',
    taskId: 'Task ID',
  },
  assignmentsPage: {
    title: 'Title',
    totalMarks: 'Total Marks Available',
    assignmentWeight: 'Weight',
    createdOn: 'Created On',
    createdBy: 'Created By',
    addNewAssignment: 'Create New Assignment',
    duplicateSelectedAssignments: 'Duplicate Selected',
    'delete-assignments': 'Delete Assignments',
    'delete-assignments-confirmation': 'Are you sure you want to delete these assignments?',
  },
  assignmentFeedback: {
    correct: {
      '0': 'Great job!',
      '1': 'Correct. Well Done.',
      '2': 'You got it!',
      '3': 'Exactly right.',
      '4': 'Correct. Way to go.',
      '5': "That's right.",
    },
    incorrect: {
      '0': "Sorry, that's incorrect. Please try again.",
      '1': "We're not quite sure where you went wrong but you're incorrect. Please try again.",
      '2': "That's not quite right. Please try again.",
      '3': "We're sorry but that's incorrect. Please try again.",
    },
    indeterminate: {
      '0': 'Your response has been saved.',
    },
    videoTasks: {
      watchToComplete: 'Watch this video to complete this task.',
      complete: 'Complete!',
      skipped:
        'You must watch more of the video. You cannot skip too many parts or watch it at too high of speed.',
      saving: 'Saving...',
      thanks: 'Thanks For Watching!',
      watchAll: 'You must watch the video to the end.',
      watchMore:
        'Full credit will be given for this task when you watch the video in its entirety.',
    },
  },
  table: 'Table',
  taskGrade: 'Task Grade',
  effective: 'effective',
  saved: 'saved',
  'analytics-overview': 'Analytics Overview',
  refresh: 'Refresh',
  chart: 'Chart',
  projects: 'Projects',
  attention: 'Attention',
  registration: {
    missingTokenTitle: 'Invitation Missing',
    missingToken: {
      inviteOnly:
        'Stemble is using an invite-only system. You will be added to the platform and will receive an email containing a registration link to allow you to claim your account.',
      requestInvitation:
        'If you have not received a link or have lost it, you can enter your email below to request another.',
    },
    resendInvitation: 'Resend Invitation',
    tooManyInvitationRequests:
      'You have submitted too many requests. Please try again after {minutes} minutes.',
    invitationExpired:
      'Your invitation has expired. You will be redirected in a few seconds so that you can request a new one.',
    invalidInvitation:
      'Your invitation is invalid. Make sure that you have properly copied the link and that you have not already used it to register before.',
  },
  placeholderImageLabels: {
    assignments: 'You have no upcoming assignments',
    events: 'You have no upcoming events',
    performanceData: 'Performance data will update as you progress through the course',
    comingSoon: 'Coming Soon!!!',
  },
  newAssignmentValidation: {
    chooseACourse: 'Choose a course',
    message: 'In order to save an assignment, you must fix the errors below:',
    errorTypes: {
      name: 'Assignment Name',
      tasks: 'Tasks',
      courseLikes: 'Assigned Course/Sections',
      dueDateNotAfterStartDate: 'Due Date',
      negativeWeight: 'Assignment Weight',
      owner: 'Belongs to',
    },
    descriptions: {
      name: 'You must give the assignment a name',
      tasks: 'You must select at least one task',
      courseLikes: 'You must choose at least one section/course for this assignment',
      dueDateNotAfterStartDate: 'The due date must be after the start date',
      negativeWeight: 'The weight must be greater than or equal to zero',
      owner: 'You must select a Course or Section the assignment belongs to',
    },
  },
  previewTips: {
    extensions: 'Click here to view assignment extensions',
    taskPreview: 'Click here to preview this task',
    deleteTask: 'Click here to remove this task',
    assignmentPreview: 'Click here to preview this assignment',
    assignmentOverview: 'Click here for the assignment overview',
  },
  'super-admin': 'Super Admin',
  sendUserInvitation: 'Send user invitation email?',
  successSnackbar: {
    dismiss: 'Dismiss',
    saveAssignment: 'Assignment Saved!',
    createdAssignment: 'Assignment Created!',
    extensionCreated: 'Extension Granted!',
    invitationSent: 'User has been sent an Invitation',
    resetEmailSent: 'A password reset email has been sent if a matching account was found',
    regradingInProgress:
      'Regrading in progress! Please note that regrading may take up to 10 minutes. Please check back after that time has passed.',
  },
  statuses: {
    notActive: 'Not using stemble',
    partial: 'Not yet registered for this course',
    registered: 'Successfully registered for this course',
  },
  taskButtonsTooltip: 'Click for options',
  noTaskButtonsTooltip: 'There are no options available for this task',
  deleteDialog: {
    participantsMessage: 'Are you sure you want to delete the following user(s): ',
    participantsAboveMaxMessage: 'Are you sure you want to delete {numberOfUsers} users?',
    participantsTitle: 'Delete Users',
    buttons: {
      no: 'Cancel',
      yes: 'Yes, Delete',
    },
  },
  responseStatusMessages: {
    [GradeStatus.Correct]: 'Correct',
    [GradeStatus.Incorrect]: 'Incorrect',
    [GradeStatus.Incomplete]: 'Not Completed',
    [GradeStatus.PartiallyCorrect]: 'Partially Correct',
    [GradeStatus.Ungraded]: 'Responded',
    adjusted: '(adjusted)',
    responded: 'Responded',
    draft: 'Draft',
  },
  gradingStatusMessages: {
    [GradingStatus.Ungraded]: 'This response is pending grading.',
    [GradingStatus.Graded]: 'The submitted response has been graded.',
    [GradingStatus.PendingJob]:
      'Grading for this submission is in progress; results will be available shortly.',
    [GradingStatus.PendingManual]: 'An instructor or TA will be notified to grade this task.',
    [GradingStatus.Failed]: 'An error occurred while grading this response.',
  },
  participantTableHeaders: {
    roles: 'Role(s)',
    sections: 'Section(s)',
  },
  participantsTooltips: {
    add: 'Click here to add new participants',
    bulkUpdate: 'Click here to add / modify bulk participants',
    edit: 'Click here to edit the selected participants',
    delete: 'Click here to delete the selected participants',
    exportCsv: 'Click here to export roster',
    exportSelectedCsv: 'Click here to export selected roster',
  },
  footer: {
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms of Use',
    contact: 'Contact Information',
  },
  editPolicy: {
    name: 'Name',
    editLateResponsePenaltyPolicy: {
      title: 'Edit Late Response Penalty Policy',
      penaltyType: 'Penalty Type',
      penaltyValue: 'Penalty Value',
      frequencyUnit: 'Time Frame (Units)',
      frequencyValue: 'Time Frame (Value)',
      cutoff: 'Use Cutoff Date',
    },
  },
  editAssignment: {
    courseHasBeenAssigned: 'This Assignment Has been assigned to {courseName}',
    editHeader: 'Assignment Settings',
    saveInfo: '*Note all edited information is automatically saved',
    tooltips: {
      startDate: 'Click here to edit this sections start date',
      dueDate: 'Click here to edit this sections due date',
    },
    chooseAllSectionsMessage:
      'It looks like you have assigned this assignment to the entire course. Would you like to assign individual dates and weights for each section?',
    cannotUnassignCourse:
      'This assignment has been assigned to the course and will have to be unassigned by somebody with permission before you can assign it.',
    noSectionsMessage:
      'This assignment is currently not assigned to any sections/courses. Please click the edit button above to get started.',
    noSectionsMessageDraft:
      'This assignment is currently not assigned to any sections/courses so it does not have a due date.',
    useFixedDurations: 'Would you like to use the same duration for all sections/courses?',
    fixedDurations: {
      number: 'Number',
      unit: 'Day/Week',
    },
  },
  allSections: 'all sections',
  loadingOverlay: {
    message: 'Loading...',
    errorLoadingUser: 'There was an error while getting user information. Please try again.',
  },
  appVersionTooltip:
    'You are running v{version} of the app. Please put this version in any support emails.',
  dragToSortInput: {
    uncategorizedLabel: 'Drag a statement to its corresponding section',
  },
  versionSnackbar: {
    refreshMessage:
      "You're running an outdated version of Stemble. Please save your work and refresh the page to get the latest version.",
  },
  selectAll: 'Select All',
  analyticsFilters: {
    assignments: 'Choose assignment(s)',
    sections: 'Filter by sections',
    users: 'Filter by users',
  },
  performanceAnalytics: {
    noAssignment: 'Choose an assignment to start viewing performance analytics!',
    noData: 'No data for the current filtering of the chosen assignment(s)',
    assignmentSubTitle: 'For {assignmentName} | For {count} assignments',
    viewTask: 'View this task',
  },
  responseHistogram: {
    title: 'Responses',
    correct: 'Correct',
    incorrect: 'Incorrect',
    yaxisTooltipSingleUserCorrect: 'Correct responses:',
    yaxisTooltipMultipleUsersCorrect: 'Mean correct responses per student:',
    yaxisTooltipSingleUserIncorrect: 'Incorrect responses:',
    yaxisTooltipMultipleUsersIncorrect: 'Mean incorrect responses per student:',
    yaxisTitle: 'Count',
    yaxisAverageTitle: 'Average Count',
    xaxisTitle: 'Task',
  },
  responseHeatMap: {
    title: 'Responses',
    yaxisTooltipSingleUser: 'Total responses:',
    yaxisTooltipMultipleUsers: 'Mean total responses per student:',
    xaxisTooltip: 'Task',
  },
  errorDonut: {
    title: 'Errors',
    clearTask: 'Clear Selected Task',
    taskSubTitle: 'For Task {taskNumber} of {assignmentName}',
    noDataMessage: 'No error data for task {taskNumber} of {assignmentName}',
  },
  noDataAvailable: 'No Data Available',
  errorAnalysisComingSoon: 'Error Analysis Coming Soon!',
  taskEditor: {
    questionId: 'Question ID',
    title: 'Task Title',
    youtubeVideoId: 'YouTube Video ID',
    textbookLink: 'Textbook link',
    taskTopics: 'Topics',
    patchTask: 'Patch Task',
    viewTask: 'View Task',
    resetTask: 'Undo all changes',
    feedbacksHeader: 'Feedbacks',
    feedbackShortContentLabel: 'Short content',
    feedbackContentLabel: 'Full Content',
    feedbackKey: 'Feedback Key',
    addScope: 'Add Task Scope',
    scopesHeader: 'Task Scopes',
    scopesInstitutionLabel: 'Institution',
    noInstitutionSelected: 'Please select an Institution',
    taskSaved: 'Task Saved',
  },
  refreshFailedHandler: {
    confirmPassword: 'Password Required',
    explanationOfPassword:
      'You are no longer logged in. Please confirm your password to continue using Stemble.',
  },
  fileUploads: {
    header: 'Response Attachments',
    preview: 'View attachment',
    download: 'Download attachment',
    delete: 'Delete attachment',
    uploadDialogTitle: 'Upload Attachment',
    uploadDialogButton: 'Upload',
    deleteConfirmationTitle: 'Confirm Attachment Delete',
    deleteConfirmationContent: 'Are you sure you would like to delete {name}?',
    uploadsSaved: 'File uploads saved',
  },
  confirmDialog: {
    defaultTitle: 'Confirmation',
    defaultContent: 'Would you like to confirm?',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  facultyGrades: {
    exportTaskState: 'Export Task State',
    exportGrades: 'Export Grades',
  },
  manualGrades: {
    greaterThanZero: 'This field must be > 0',
    greaterThanEqualZero: 'This field must be >= 0',
    lessThanDenominator: 'This field must be <= denominator',
    gradeEnteredBy: 'Grade manually entered by: {name}',
    gradeLabel: 'Grade',
    feedbackForStudentLabel: 'Feedback for Student',
    instructorFeedbackLabel: 'Instructor Feedback',
    noInstructorFeedbackText: 'No Feedback was given by your instructor.',
    editGradeLabel: 'Edit Grade',
    dialogTitle: 'Manual Grade Entry',
    maxDenominator: 'Denominator must be <= {maxDenominator}',
    maxPrecision: 'Must have precision <= {maxPrecision}',
  },
  gradeAdjustments: {
    title: 'Adjustments',
    late: 'late',
    points: 'point | points',
    relativeBonusReason: "Congrats! You've been given a {adjustment}% bonus.",
    absoluteBonusReason: "Congrats! You've been given a {adjustment} point bonus.",
    relativeLatePenaltyReason:
      'Because your response was submitted late, a {adjustment}% penalty has been applied.',
    absoluteLatePenaltyReason:
      ' Because your response was submitted late, a {adjustment} point penalty has been applied.',
    relativeHighestAchievableGrade: 'The highest achievable grade is {grade}%.',
    absoluteHighestAchievableGrade: 'The highest achievable point total is {grade}.',
  },
  assignmentOverview: {
    policiesTab: 'Policies',
  },
  policyDescriptions: {
    dueDateGradeVisibility: 'Due Date Grade Visibility Policy',
    finiteResponse: 'Finite Response Policy',
    finiteStatesResponse: 'Finite States Response Policy',
    grade: 'Grade Policy',
    manualGradeVisibility: 'Manual Grade Visibility Policy',
    pastDueResponse: 'Past Due Response Policy',
    visibleDateGradeVisibility: 'Visible Date Grade Visibility Policy',
    lateResponsePenalty: 'Late Response Penalty Policy',
  },
  courseCreate: {
    useWizardPrompt: 'Be guided through the course creation process',
    useWizardButton: 'Use Wizard',
    useEditorPrompt: 'Edit your course quickly on a single screen',
    useEditorButton: 'Use Advanced Editor',
  },
  courseSettings,
  courseWizard,
  support: 'Support',
  links: {
    privacyPolicy: 'https://www.stemble.com/privacy-policy',
    termsAndConditions: 'https://www.stemble.com/terms-and-conditions',
  },
  cookieConsent: {
    title: 'We care about your privacy',
    subText:
      'Stemble uses cookies to provide an enhanced experience to our users. ' +
      'Please provide your consent to us storing cookies in your browser. To find out ' +
      'more about the types of cookies we store, please visit our ',
    settings: {
      title: 'Cookie Preferences',
      subText:
        'Stemble uses both first and third party cookies to enhance user experience. ' +
        'You will find detailed information about the types of cookies we store in the categories below. ' +
        'You may choose to disable some or all of these cookies, but disabling them may ' +
        'affect your browsing experience.',
      hoverNecessary: 'Necessary cookies must be accepted to use our website.',
    },
    buttons: {
      acceptAll: 'Accept All',
      settings: 'Settings',
      save: 'Save Preferences',
    },
    categories: {
      [ConsentCategory.All]: 'All',
      [ConsentCategory.Necessary]: 'Necessary',
      [ConsentCategory.Functional]: 'Functional',
      [ConsentCategory.Analytics]: 'Analytics',
      [ConsentCategory.Support]: 'Support',
    },
    descriptions: {
      [ConsentCategory.Necessary]:
        'Necessary cookies are required for basic website functionality. ' +
        'They include no personalized information, and must be accepted.',
      [ConsentCategory.Functional]:
        'Functional cookies provide advanced functionality for the website, ' +
        'including guided tours of various workflows on the website.',
      [ConsentCategory.Analytics]:
        'Analytics cookies are used to understand how users interact with the website. ' +
        'They provide information on a variety of statistics including page visits, bounce rates, ' +
        'traffic sources, etc.',
      [ConsentCategory.Support]:
        'Support cookies are used to provide enhanced support features to users, ' +
        'including error tracking and reporting, and chatbot services.',
    },
  },
  courseHome: {
    metadata: {
      title: 'Course Info',
      endDate: 'Ends',
      startDate: 'Starts',
      trialExpiryDate: 'Free Trial End',
    },
  },
  taskCard: {
    submissionTooLargeTitle: 'Submission Too Large',
    submissionTooLarge:
      'Your submission is too large.  Please try and reduce the size of your attachments and try again.',
  },
  institutionSelector,
  institutionEditor,
  userSelector,
  simulations,
};
