








































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'UCIrvineELN2v2DataAnalysis3',
  components: {FileUploadUiComponent, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        dataAnalysis2Calculations: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: 'Any written explanations or calculations may be included below.',
          inputName: 'dataAnalysis2Calculations',
        },
      ],
    };
  },
});
