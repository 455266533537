<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Two students determine the percentage of lead in a sample as a laboratory exercise. The
        actual percentage of lead in the sample is
        <number-value :value="actualValue" unit="\text{\%}" />. The students' performed 3 trials
        each and obtained the following results:
      </p>

      <p class="mb-4">
        <v-simple-table>
          <thead>
            <tr>
              <th style="font-size: 15px">Student</th>
              <th style="font-size: 15px; text-align: center">Trial 1</th>
              <th style="font-size: 15px; text-align: center">Trial 2</th>
              <th style="font-size: 15px; text-align: center">Trial 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td style="text-align: center">
                <number-value :value="s1t1" />
              </td>
              <td style="text-align: center">
                <number-value :value="s1t2" />
              </td>
              <td style="text-align: center">
                <number-value :value="s1t3" />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td style="text-align: center">
                <number-value :value="s2t1" />
              </td>
              <td style="text-align: center">
                <number-value :value="s2t2" />
              </td>
              <td style="text-align: center">
                <number-value :value="s2t3" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </p>

      <p class="mb-2">a) What is the average of the three trials performed by Student 1?</p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-4"
        prepend-text="Lead Content:"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the average of the three trials performed by Student 2?</p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-4"
        prepend-text="Lead Content:"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Which of the two students was more <b>accurate</b>?</p>
      <v-radio-group v-model="inputs.input3" row class="ml-6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        Precision can be judged by examining the average of the deviations from the average value
        for that data set. Calculate how each student's measurement differs from the average value
        in their set and then average the absolute value of those deviations.
      </p>
      <p class="mb-2">d) What is the precision of the data obtained by Student 1?</p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-3"
        prepend-text="Precision:"
        :disabled="!allowEditing"
      />
      <p class="mb-2">e) What is the precision of the data obtained by Student 2?</p>
      <calculation-input
        v-model="inputs.input5"
        class="mb-6"
        prepend-text="Precision:"
        :disabled="!allowEditing"
      />

      <p class="mb-2">f) Which of the two students is more <b>precise</b>?</p>
      <v-radio-group v-model="inputs.input6" row class="ml-6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question191',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options1: [
        {text: 'Student 1', value: 'student1'},
        {text: 'Student 2', value: 'student2'},
      ],
    };
  },
  computed: {
    actualValue() {
      return this.taskState.getValueBySymbol('actualValue').content;
    },
    s1t1() {
      return this.taskState.getValueBySymbol('s1t1').content;
    },
    s1t2() {
      return this.taskState.getValueBySymbol('s1t2').content;
    },
    s1t3() {
      return this.taskState.getValueBySymbol('s1t3').content;
    },
    s2t1() {
      return this.taskState.getValueBySymbol('s2t1').content;
    },
    s2t2() {
      return this.taskState.getValueBySymbol('s2t2').content;
    },
    s2t3() {
      return this.taskState.getValueBySymbol('s2t3').content;
    },
  },
};
</script>
