<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A
        <number-value :value="volume" unit="\text{L}" />
        reaction vessel contains only
        <stemble-latex :content="compound" />
        gas. The system is kept at
        <number-value :value="temp" unit="^\circ\text{C}" />
        and the pressure inside the vessel is found to be
        <number-value :value="pressure" unit="\text{atm.}" />
      </p>

      <p class="mb-3">
        a) Find the number of moles of
        <stemble-latex :content="compound" />
        in the reaction vessel.
      </p>

      <calculation-input
        v-model="inputs.molGas"
        class="mb-5"
        prepend-text="$\text{Moles:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Determine the number of molecules of
        <stemble-latex :content="compound" />
        in the system.
      </p>

      <calculation-input
        v-model="inputs.mlcGas"
        class="mb-4"
        prepend-text="$\text{Molecules:}$"
        :disabled="!allowEditing"
      />

      <p>
        <small
          ><b>Helpful tip:</b> To enter a number in scientific notation, enter 1.0E5 or 1.0e5, for
          1.0 x 10<sup>5</sup> where the E# (or e#) denotes 10<sup>#</sup></small
        >
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question248',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molGas: null,
        mlcGas: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    molecule() {
      return this.taskState.getValueBySymbol('molecule').content;
    },
    compound() {
      if (this.molecule.value === 1) {
        return '$\\ce{CO2}$';
      } else if (this.molecule.value === 2) {
        return '$\\ce{O2}$';
      } else if (this.molecule.value === 3) {
        return '$\\ce{N2}$';
      } else if (this.molecule.value === 4) {
        return '$\\ce{Ar}$';
      } else if (this.molecule.value === 5) {
        return '$\\ce{He}$';
      } else {
        return '';
      }
    },
  },
};
</script>
