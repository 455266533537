<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
        (Recalculate actual values based on what is weighed out in lab for in-lab and lab report
        calculations.)
      </p>
      <p class="mt-5">Table 1:</p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th colspan="3">Reactants</th>
            <th>Product</th>
          </tr>
          <tr>
            <th v-for="head in headings1" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Formula</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaCin1"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaACP1"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaNaOH1"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaProd1"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Molar Mass (g/mol)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmCin1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmACP1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmNaOH1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmProd1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Volume (mL)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCin1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vACP1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vNaOH1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Theoretical Yield (g)</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.yieldProd1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Density (g/mol)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dCin1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dACP1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Concentration (M)</td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cNaOH1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Moles (mol)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molCin1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molACP1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Table 2:</p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th colspan="3">Reactants</th>
            <th>Product</th>
          </tr>
          <tr>
            <th v-for="head in headings2" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Formula</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaCin2"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaACP2"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaNaOH2"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaProd2"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Molar Mass (g/mol)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmCin2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmACP2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmNaOH2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmProd2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Volume (mL)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vCin2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vACP2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vNaOH2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Theoretical Yield (g)</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.yieldProd2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Density (g/mol)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dCin2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dACP2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Concentration (M)</td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cNaOH2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Moles (mol)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molCin2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molACP2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <v-select
        v-model="inputs.equipment"
        :items="equipmentOptions"
        multiple
        chips
        class="ml-6 mb-n4 mt-0"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LDFS4IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaCin1: null,
        mmCin1: null,
        vCin1: null,
        dCin1: null,
        molCin1: null,

        chemFormulaACP1: null,
        mmACP1: null,
        vACP1: null,
        dACP1: null,
        molACP1: null,

        chemFormulaNaOH1: null,
        mmNaOH1: null,
        vNaOH1: null,
        cNaOH1: null,

        chemFormulaProd1: null,
        mmProd1: null,
        yieldProd1: null,

        chemFormulaCin2: null,
        mmCin2: null,
        vCin2: null,
        dCin2: null,
        molCin2: null,

        chemFormulaACP2: null,
        mmACP2: null,
        vACP2: null,
        dACP2: null,
        molACP2: null,

        chemFormulaNaOH2: null,
        mmNaOH2: null,
        vNaOH2: null,
        cNaOH2: null,

        chemFormulaProd2: null,
        mmProd2: null,
        yieldProd2: null,

        equipment: [],
      },
      headings1: [
        {text: ''},
        {text: 'Cinnamaldehyde'},
        {text: 'Acetophenone'},
        {text: 'Sodium Hydroxide'},
        {text: '1,5-diphenyl-penta-2,4-dien-1-one'},
      ],
      headings2: [
        {text: ''},
        {text: 'Cinnemaldehyde'},
        {text: 'Acetophenone'},
        {text: 'Sodium Hydroxide'},
        {text: 'Dicinnamalacetone'},
      ],

      equipmentOptions: [
        'beaker(s)',
        'Buchner funnel',
        'buret',
        'capillary/melting tube',
        'clamp and stand',
        'cuvette',
        'Digi-Melt (melting point apparatus)',
        'digital balance',
        'disposable pipet',
        'drying oven',
        'Erlenmeyer flask',
        'filter paper',
        'funnel',
        'graduated cylinder',
        'ice',
        'KimWipes (tissues)',
        'LabQuest',
        'pencil',
        'pipet suction bulb',
        'probe (pH)',
        'probe (temperature)',
        'scintillation vial',
        'side arm flask',
        'spatula or scoopula',
        'spectrometer',
        'spotter',
        'stir bar',
        'stirrer/hotplate',
        'test tube',
        'thermometer (alcohol)',
        'TLC (developing) chamber',
        'TLC plate',
        'TLC spotter',
        'UV lamp',
        'volumetric flask',
        'volumetric pipet',
        'watch glass',
        'weighing boat or paper',
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
