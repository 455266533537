




































import {defineComponent, PropType, ref, toRef, watch} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import TaskState from '@/task-states/models/TaskState';

export default defineComponent({
  name: 'MultiSelectUiComponent',
  components: {StembleLatex},
  props: {
    options: {
      type: Array as PropType<{key: string; label: string}[]>,
      default: () => [],
    },
    uiType: {
      type: String as PropType<'checkboxes' | 'list'>,
      default: 'checkboxes',
    },
    selectedOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    taskState: {
      type: Object as PropType<TaskState | null>,
      default: null,
    },
    label: {
      type: String as PropType<string>,
      default: null,
    },
  },
  emits: {
    'update:selectedOptions': (options: (string | number)[]) => {},
  },
  setup(props, {emit}) {
    const clonedSelectedOptions = ref([...(props.selectedOptions || [])]);

    watch(toRef(props, 'selectedOptions'), () => {
      clonedSelectedOptions.value = [...(props.selectedOptions || [])];
    });

    function selectOption(key: string, value: boolean) {
      clonedSelectedOptions.value.push(key);
      if (!value) {
        clonedSelectedOptions.value = clonedSelectedOptions.value.filter((item) => item !== key);
      }

      emitSelectedOptions();
    }

    function emitSelectedOptions() {
      emit('update:selectedOptions', clonedSelectedOptions.value);
    }

    const inputGroupId = Math.random().toString(36).substring(7);

    return {clonedSelectedOptions, selectOption, emitSelectedOptions, inputGroupId};
  },
});
