









































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem51LBProject1FS1Argumentation',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        argumentation1Answer: null,
        argumentation2Answer: null,
        argumentation3Answer: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: 'Answer to Fundamental Skills Guiding Question(s) for Unknown Substance #1:',
          inputName: 'argumentation1Answer',
        },
        {
          text: 'Answer to Fundamental Skills Guiding Question(s) for Unknown Substance #2:',
          inputName: 'argumentation2Answer',
        },
        {
          text: 'Answer to Fundamental Skills Guiding Question(s) for Unknown Substance #3:',
          inputName: 'argumentation3Answer',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
