<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the hypothetical first-order reaction shown below:</p>
      <p class="mb-4 pl-5">
        <chemical-latex content="A -> products" />
      </p>
      <p class="mb-4">
        At
        <stemble-latex content="$75~^\circ\text{C,}$" />
        only
        <number-value :value="percentage" />
        <stemble-latex content="$\%$" />
        of the sample remains after a period of
        <number-value :value="reactionTime" unit="\text{s.}" />
        Determine the rate constant,
        <stemble-latex content="$k,$" />
        for the reaction at this temperature.
      </p>

      <calculation-input
        v-model="inputs.rateConstant"
        prepend-text="$k:$"
        append-text="$\text{s}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question152',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateConstant: null,
      },
    };
  },
  computed: {
    reactionTime() {
      return this.taskState.getValueBySymbol('reactionTime').content;
    },
    percentage() {
      return this.taskState.getValueBySymbol('percentage').content;
    },
  },
};
</script>
