<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Le tableau suivant liste les données de spectrométrie de masse pour trois alcools
        isomériques avec la formule <chemical-latex content="C5H12O" />. En vous basant sur les
        positions et intensités des pics, proposez des structures pour chacun des trois isomères.
      </p>
      <p class="mb-5">
        <v-img :src="imageHeader" style="max-width: 550px" />
      </p>

      <s-textarea v-model="inputs.input1" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'UdeMOchemA1Ex60',
  components: {
    ChemicalLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    imageHeader() {
      return '/img/assignments/UdeMOChem/MS-Table.png';
    },
  },
};
</script>
