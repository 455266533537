










import {computed, defineComponent, PropType} from '@vue/composition-api';
import {computedAsync} from '@vueuse/core';

export default defineComponent({
  name: 'DataDrivenComponent',
  inheritAttrs: false,
  props: {
    taskId: {
      type: Number as PropType<number>,
      required: true,
    },
    componentModule: {
      type: null as unknown as PropType<Promise<{default: any}>>,
      required: true,
    },
    allData: {
      // We use null here to bypass Vue's run-time type checks
      type: null as unknown as PropType<Record<string, any> | null>,
      default: null,
    },
  },
  setup(props, {emit}) {
    const uiComponentMetadata = computedAsync(async () => {
      const {default: component} = await props.componentModule;

      const writablePropNames = Object.keys(component?.emits || {})
        .filter((evt) => evt.startsWith('update:'))
        .map((evt) => evt.replace('update:', ''));

      return {
        component,
        writablePropNames,
      };
    }, null);

    const componentType = computed(() => uiComponentMetadata.value?.component ?? null);

    const listeners = computed(() =>
      Object.fromEntries(
        uiComponentMetadata.value?.writablePropNames.map((propName) => [
          `update:${propName}`,
          (v: any) => emitValueChanged(propName, v),
        ]) ?? []
      )
    );

    const writablePropsData = computed(() => {
      const writablePropNames = uiComponentMetadata.value?.writablePropNames ?? [];

      return Object.fromEntries(writablePropNames.map((k) => [k, props.allData?.[k] ?? null]));
    });

    const emitValueChanged = (key: string, value: any) => {
      emit(`update:${key}`, value);
      const newValue = {
        ...props.allData,
        [key]: value,
      };
      emit('update:allData', newValue);
    };

    return {
      componentType,
      listeners,
      writablePropsData,
    };
  },
});
