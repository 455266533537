<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Acetic Acid</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaAcA"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateAcA" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Sodium Acetate</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaNaAc"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmNaAc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateNaAc" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vNaAc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cNaAc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Ammonium Chloride</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaNH4Cl"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmNH4Cl"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateNH4Cl" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vNH4Cl"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cNH4Cl"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Ammonium Hydroxide</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaNH4OH"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmNH4OH"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateNH4OH" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vNH4OH"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cNH4OH"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Hydrochloric Acid</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaHCl"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmHCl"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateHCl" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vHCl"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cHCl"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Sodium Hydroxide</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaNaOH"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmNaOH"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateNaOH" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vNaOH"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cNaOH"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>pH 7 buffer</td>
            <td style="width: 150px">
              <s-textarea v-model="inputs.chemFormulaBuff" outlined />
            </td>
            <td>N/A</td>
            <td>
              <v-select v-model="inputs.physStateBuff" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vBuff"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <v-select
        v-model="inputs.equipment"
        :items="equipmentOptions"
        multiple
        chips
        class="ml-6 mb-n4 mt-0"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'ChemUCI1LCFS4IL2',
  components: {
    STextarea,
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaAcA: null,
        mmAcA: null,
        physStateAcA: null,
        vAcA: null,
        cAcA: null,
        chemFormulaNaAc: null,
        mmNaAc: null,
        physStateNaAc: null,
        vNaAc: null,
        cNaAc: null,
        chemFormulaNH4Cl: null,
        mmNH4Cl: null,
        physStateNH4Cl: null,
        vNH4Cl: null,
        cNH4Cl: null,
        chemFormulaNH4OH: null,
        mmNH4OH: null,
        physStateNH4OH: null,
        vNH4OH: null,
        cNH4OH: null,
        chemFormulaHCl: null,
        mmHCl: null,
        physStateHCl: null,
        vHCl: null,
        cHCl: null,
        chemFormulaNaOH: null,
        mmNaOH: null,
        physStateNaOH: null,
        vNaOH: null,
        cNaOH: null,
        chemFormulaBuff: null,
        physStateBuff: null,
        vBuff: null,
        equipment: [],
      },
      headings: [
        {text: 'Chemical Name'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Physical States'},
        {text: 'Total Volume Used (mL)'},
        {text: 'Concentration (M)'},
      ],
      stateOptions: ['l', 's', 'g', 'aq'],
      equipmentOptions: [
        'beaker(s)',
        'Buchner funnel',
        'buret',
        'capillary/melting tube',
        'clamp and stand',
        'cuvette',
        'Digi-Melt (melting point apparatus)',
        'digital balance',
        'disposable pipet',
        'drying oven',
        'Erlenmeyer flask',
        'filter paper',
        'funnel',
        'graduated cylinder',
        'ice',
        'KimWipes (tissues)',
        'LabQuest',
        'pencil',
        'pipet suction bulb',
        'probe (pH)',
        'probe (temperature)',
        'scintillation vial',
        'side arm flask',
        'spatula or scoopula',
        'spectrometer',
        'spotter',
        'stir bar',
        'stirrer/hotplate',
        'test tube',
        'thermometer (alcohol)',
        'TLC (developing) chamber',
        'TLC plate',
        'TLC spotter',
        'UV lamp',
        'volumetric flask',
        'volumetric pipet',
        'watch glass',
        'weighing boat or paper',
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
