<template>
  <stemble-card
    color="rgba(11,87,155)"
    image
    data-test="assignment"
    :data-test-id="assignment.id"
    padding="3"
  >
    <template #heading>
      <s-stack gap="1" direction="vertical" style="white-space: nowrap; overflow: hidden">
        <s-stack>
          <p
            class="font-weight-medium text-h4"
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
          >
            {{ assignment.name }}
          </p>
          <p class="text-subtitle-2">
            {{ assignment.course.name }}
          </p>
        </s-stack>

        <p class="text-body-1">{{ $t('due') }} {{ formattedDueDate }}</p>
      </s-stack>
    </template>

    <s-stack direction="horizontal" align="space-between" valign="center" wrap gap="2">
      <div>
        <v-btn
          :ripple="false"
          class="no-background-hover ma-0"
          elevation="1"
          color="secondary"
          rounded
          small
          :href="`/web/courses/${assignment.courseId}/assignments/${assignment.id}/tasks/first`"
          data-test="assignment-preview-btn"
        >
          <v-icon small class="mr-1" style="position: relative; bottom: 1px">
            {{ $i('assignment.previewAssignment') }}
          </v-icon>
          {{ $t('go-to-assignment') }}
        </v-btn>
      </div>

      <s-stack direction="horizontal" gap="2">
        <v-tooltip open-delay="500" top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              class="ma-0"
              fab
              x-small
              v-bind="attrs"
              color="secondary"
              elevation="2"
              :href="`/courses/${assignment.courseId}/assignments/${assignment.id}/edit`"
              aria-label="Edit Assignment"
              v-on="on"
            >
              <v-icon role="presentation">{{ $i('assignment.editAssignment') }}</v-icon>
            </v-btn>
          </template>
          {{ $t('editAssignmentButton') }}
        </v-tooltip>

        <v-tooltip open-delay="500" top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              class="ma-0"
              fab
              x-small
              v-bind="attrs"
              color="secondary"
              elevation="2"
              :href="`/courses/${assignment.courseId}/assignments/${assignment.id}/grades`"
              aria-label="View Grades"
              v-on="on"
            >
              <v-icon role="presentation">mdi-table-check</v-icon>
            </v-btn>
          </template>
          {{ $t('grades') }}
        </v-tooltip>

        <v-tooltip open-delay="500" top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              class="mr-0"
              fab
              x-small
              v-bind="attrs"
              color="secondary"
              elevation="2"
              :href="`/courses/${assignment.courseId}/assignments/${assignment.id}/extensions`"
              aria-label="View Assignment Extensions"
              v-on="on"
            >
              <v-icon role="presentation">{{ $i('assignment.extensions') }}</v-icon>
            </v-btn>
          </template>
          {{ $t('viewAssignmentExtensions') }}
        </v-tooltip>
      </s-stack>
    </s-stack>
  </stemble-card>
</template>

<script>
import moment from 'moment';
import {ASSIGNMENT_OVERVIEW, QUIZ} from '@/router/route-names';
import StembleCard from '@/common/components/StembleCard.vue';
import SStack from '@/common/components/SStack.vue';

export default {
  name: 'FacultyAssignmentCard',
  components: {StembleCard, SStack},
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      isToggled: false,
      numberOfNotStartedAssignmrnt: 0,
      numberOfStartedAssignmrnt: 0,
      numberOfCompletedAssignmrnt: 0,
      numberOfGardedAssignmrnt: 0,
    };
  },
  computed: {
    courseLikeAssignment() {
      return this.assignment.courseLikeAssignments[0];
    },
    formattedDueDate() {
      return moment(this.courseLikeAssignment.dueDate).format('llll');
    },
    formattedStartDate() {
      return moment(this.courseLikeAssignment.startDate).format('llll');
    },
  },
};
</script>
