<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">Consider the following reaction:</p>

      <p class="mb-5 pl-6">
        <chemical-latex content="A + B -> 2 C" />
        <stemble-latex content="$\qquad\text{E}_\text{a}=$" />
        <number-value :value="Ea" unit="\text{kJ/mol}" />
      </p>

      <p class="mb-3">
        If the rate constant for this reaction at
        <number-value :value="Temp1" unit="\text{K}" />
        is
        <number-value :value="rateConstant1" unit="\text{s}^{-1}," />
        determine the the temperature required to increase the rate constant to
        <number-value :value="rateConstant2" unit="\text{s}^{-1}." />
      </p>

      <calculation-input
        v-model="inputs.Temp2"
        prepend-text="$\text{T:}$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question154',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Temp2: null,
      },
    };
  },
  computed: {
    rateConstant1() {
      return this.taskState.getValueBySymbol('rateConstant1').content;
    },
    Temp1() {
      return this.taskState.getValueBySymbol('Temp1').content;
    },
    rateConstant2() {
      return this.taskState.getValueBySymbol('rateConstant2').content;
    },
    Ea() {
      return this.taskState.getValueBySymbol('Ea').content;
    },
  },
};
</script>
