





































import {defineComponent, PropType} from '@vue/composition-api';
import i18n from '@/locales/composables/i18n';
import {en as enComponentTranslations} from '@/locales/en/components';
import {fr as frComponentTranslations} from '@/locales/fr/components';

export default defineComponent({
  name: 'FileUploadUiComponent',
  props: {
    showAcceptedFileTypes: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    acceptedFileExtensions: {
      type: String as PropType<string | null>,
      required: false,
    },
    maxNumberOfFiles: {
      type: Number as PropType<number | null>,
      required: false,
    },
    maxFileSize: {
      type: Number as PropType<number | null>,
      required: false,
    },
    label: {
      type: String as PropType<string>,
      default: null,
    },
  },
  emits: ['upload:attachments'],
  created() {
    i18n.mergeLocaleMessage('en', enComponentTranslations);
    i18n.mergeLocaleMessage('fr', frComponentTranslations);
  },
  data() {
    return {
      attachments: [] as File[],
    };
  },
  computed: {
    maximumFileSizeInMB(): number {
      return this.maxFileSize || 50; // Default 50 MB
    },
    maximumFileSizeInBytes(): number {
      return this.maximumFileSizeInMB * 1024 * 1024;
    },
    maximumFileSizeLabel(): string {
      const localizedNum = this.$n(this.maximumFileSizeInMB);
      return this.$t('fileUploadUiComponent.formattedMaxFileSize', {num: localizedNum}) as string;
    },
    acceptedFileExtensionLabel(): string {
      if (!this.acceptedFileExtensions) {
        return '';
      }

      return this.acceptedFileExtensions
        .split(',')
        .map((ext: string) => ext.trim().replace(/\./g, '').toUpperCase())
        .join(', ');
    },
    generatedRules(): any[] {
      // Set maximum file size.
      const rules: any[] = [];
      const validateSingleFileSize = (value: File | null | undefined): true | string => {
        if (!value) {
          return true;
        }

        const fileExceedsLimit = value.size > this.maximumFileSizeInBytes;

        if (fileExceedsLimit) {
          return this.$t('fileUploadUiComponent.fileExceedsLimit', {
            limit: this.maximumFileSizeLabel,
          }) as string;
        }

        return true;
      };

      // Add validator for file size, that can operate on both single and multiple files
      rules.push((value: File | File[] | undefined) => {
        if (!value) {
          return true;
        }

        if (Array.isArray(value)) {
          const results = value.map(validateSingleFileSize);

          return results.find((result) => result !== true) || true;
        } else {
          return validateSingleFileSize(value);
        }
      });

      // conditionally add validation run for number of files if we're in multi mode.
      if (this.maxNumberOfFiles && this.maxNumberOfFiles > 1) {
        rules.push((value: File[] | undefined) => {
          if (!value || !value.length) {
            return true;
          }

          if (value.length > this.maxNumberOfFiles!) {
            return this.$t('fileUploadUiComponent.tooManyFiles', {
              num: this.maxNumberOfFiles,
            });
          }

          return true;
        });
      }

      return rules;
    },
  },
  watch: {
    attachments: {
      handler(newVal: File | File[] | null | undefined) {
        // If not in a valid state, do not emit the upload event
        if (!(this.$refs.fileInputField as any)?.validate()) {
          return;
        }

        // We want to emit the attachments as an array, even if it's a single file
        // This allows the parent component to treat the attachments uniformly
        if (!newVal) {
          this.$emit('upload:attachments', []);
        } else if (Array.isArray(newVal)) {
          this.$emit('upload:attachments', newVal);
        } else {
          this.$emit('upload:attachments', [newVal]);
        }
      },
    },
  },
});
