<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Determine whether each of the materials listed below would be classified as pure substances,
        homogeneous mixtures, or heterogeneous mixtures.
      </p>
      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Substance</th>
            <th style="font-size: 13px">Pure Substance</th>
            <th style="font-size: 13px">Homogeneous Mixture</th>
            <th style="font-size: 13px">Heterogeneous Mixture</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Concrete</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Seawater</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Magnesium</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Gasoline</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Air</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Nitrogen</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Iodine crystals</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Blue-cheese salad dressing</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input8" :disabled="!allowEditing">
                <v-radio key="pure" value="pure" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input8" :disabled="!allowEditing">
                <v-radio key="homo" value="homo" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input8" :disabled="!allowEditing">
                <v-radio key="hetero" value="hetero" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question181',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
    };
  },
};
</script>
