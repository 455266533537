

































































































































































































































import {defineComponent, PropType, reactive, toRef} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {useMcMasterLab6Plot} from '@/tasks/composables/UseMcMasterLab6Plot';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

interface TableRow {
  volTitrant: number | null;
  pH: number | null;
}

export default defineComponent({
  name: 'ChemMcM1AA3Lab6Results',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      options1: [
        {text: '$\\ce{CH3COOH}$', value: 'CH3COOH'},
        {text: '$\\ce{HCl}$', value: 'HCl'},
        {text: '$\\ce{NaOH}$', value: 'NaOH'},
        {text: '$\\ce{NH3}$', value: 'NH3'},
      ],
    };
  },
  setup(props) {
    const inputs = reactive({
      titrant: null,
      analyte: null,
      initialPH: null,
      rows: [
        {volTitrant: null, pH: null},
        {volTitrant: null, pH: null},
        {volTitrant: null, pH: null},
      ] as TableRow[],
      pHEqPt: null,
      Veq: null,
      molTitrantEq: null,
      molAnalyte: null,
      volAnalyte: null,
      concAnalyte: null,
      pHHalfEqPt: null,
      pKa: null,
      Ka: null,
    });
    const rows = toRef(inputs, 'rows');
    const {apexSeries, apexOptions, addRun, removeRun} = useMcMasterLab6Plot({
      labels: {volTitrant: 'Volume (mL)', pH: 'pH'},
      x: 'volTitrant',
      y: 'pH',
      rows,
      minX: 0.0,
      maxX: 25.0,
      minY: 0.0,
      maxY: 14.0,
    });

    return {
      rows,
      inputs,
      apexOptions,
      apexSeries,
      addRun,
      removeRun,
    };
  },
});
