











import {defineComponent, PropType} from '@vue/composition-api';

export default defineComponent({
  name: 'ImageUiComponent',
  props: {
    taskId: {
      type: Number as PropType<number>,
      required: true,
    },
    fileUploadId: {
      type: Number as PropType<number | null>,
    },
    alt: {
      type: String as PropType<string>,
      default: null,
    },
    width: {
      type: String as PropType<string>,
      default: 'auto',
    },
  },
});
