<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Hydrazoic acid,
        <chemical-latex content="HN3," />
        has a
        <stemble-latex content="$\text{K}_\text{a}$" />
        value of
        <stemble-latex content="$1.9\times 10^{-5}.$" />
        If you prepare a
        <number-value :value="concA" unit="\text{M}" />
        solution of
        <chemical-latex content="HN3," />
        answer each of the questions listed below.
      </p>

      <p class="mb-3">
        a) Based on the information provided above, fill in the ICE table below inputting
        <stemble-latex content="$\mathrm{x}$" />
        for any relative changes. Be sure to indicate the direction of change, i.e. +/-. Be sure to
        use the proper number of significant figures. Any zeroes should be denoted as 0. The
        equilibrium row should contain the
        <stemble-latex content="$\mathrm{x}$" /> variable.
      </p>

      <v-simple-table class="mb-8" dense>
        <thead class="text-center">
          <tr>
            <th style="vertical-align: middle; text-align: center; font-size: 15px" />
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="HN3(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="H2O(l)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="<=>" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="N3^-(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="H3O^+(aq)" />
            </th>
          </tr>

          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{I}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialHN3" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialN3" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialH3O" group-size="sm" class="centered-input" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{C}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeHN3" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeN3" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeH3O" class="centered-input" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{E}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumHN3" class="centered-input" />
            </td>
            <td></td>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumN3" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumH3O" class="centered-input" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">
        b) Determine
        <chemical-latex content="[H3O+]" />
        once the reaction reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.valueOfx"
        prepend-text="$\ce{[H3O+]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-3">
        c) Determine the
        <stemble-latex content="$\text{pH}$" />
        of the system when it reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import IceInput from '../inputs/IceInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question159',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    IceInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        initialHN3: null,
        initialH3O: null,
        initialN3: null,
        changeHN3: null,
        changeH3O: null,
        changeN3: null,
        equilibriumHN3: null,
        equilibriumH3O: null,
        equilibriumN3: null,
        valueOfx: null,
        pH: null,
      },
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
