<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Assuming you have equal amounts of each of the substances at the same temperature, rank each
        of the compounds in the following 3 sets from the highest down to the lowest absolute
        entropy.
      </p>
      <p class="mb-4">a) <stemble-latex :content="`${items1[0]}, ${items1[1]}, ${items1[2]}`" /></p>
      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items1"
        class="mb-10 pl-8"
        style="width: 500px"
        prepend-text="Highest"
        append-text="Lowest"
      />

      <p class="mb-4">b) <stemble-latex :content="`${items2[0]}, ${items2[1]}, ${items2[2]}`" /></p>
      <drag-to-rank-input
        v-model="inputs.input2"
        :items="items2"
        class="mb-10 pl-8"
        style="width: 500px"
        prepend-text="Highest"
        append-text="Lowest"
      />

      <p class="mb-4">c) <stemble-latex :content="`${items3[0]}, ${items3[1]}, ${items3[2]}`" /></p>
      <drag-to-rank-input
        v-model="inputs.input3"
        :items="items3"
        class="mb-5 pl-8"
        style="width: 500px"
        prepend-text="Highest"
        append-text="Lowest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question293',
  components: {
    DragToRankInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
    };
  },
  computed: {
    versionNumberA() {
      return this.taskState.getValueBySymbol('versionNumberA').content;
    },
    versionNumberB() {
      return this.taskState.getValueBySymbol('versionNumberB').content;
    },
    versionNumberC() {
      return this.taskState.getValueBySymbol('versionNumberC').content;
    },
    items1() {
      if (this.versionNumberA.value === 1) {
        return ['$\\ce{H2(g)}$', '$\\ce{HBrO4(g)}$', '$\\ce{HBr(g)}$'];
      } else if (this.versionNumberA.value === 2) {
        return ['$\\ce{HCl(g)}$', '$\\ce{HClO3(g)}$', '$\\ce{H2(g)}$'];
      } else if (this.versionNumberA.value === 3) {
        return ['$\\ce{H2SO4(l)}$', '$\\ce{H2(l)}$', '$\\ce{H2O(l)}$'];
      } else {
        return [];
      }
    },
    items2() {
      if (this.versionNumberB.value === 1) {
        return ['$\\ce{H2O(l)}$', '$\\ce{H2O(s)}$', '$\\ce{H2O(g)}$'];
      } else if (this.versionNumberB.value === 2) {
        return ['$\\ce{O2(g)}$', '$\\ce{O3(g)}$', '$\\ce{O(g)}$'];
      } else if (this.versionNumberB.value === 3) {
        return ['$\\ce{CO2(l)}$', '$\\ce{CO2(g)}$', '$\\ce{CO2(s)}$'];
      } else {
        return [];
      }
    },
    items3() {
      if (this.versionNumberC.value === 1) {
        return ['$\\ce{S8(s)}$', '$\\ce{Li(s)}$', '$\\ce{P4(s)}$'];
      } else if (this.versionNumberC.value === 2) {
        return ['$\\ce{He(g)}$', '$\\ce{Cl2(g)}$', '$\\ce{P4(g)}$'];
      } else if (this.versionNumberC.value === 3) {
        return ['$\\ce{Ar(g)}$', '$\\ce{He(g)}$', '$\\ce{Ne(g)}$'];
      } else {
        return [];
      }
    },
  },
};
</script>
