<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Red 40 Stock Solution</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaR40"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmR40"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateR40" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vR40"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cR40"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Blue 1 Stock Solution</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaB1"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmB1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateB1" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vB1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cB1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Yellow 5 Stock Solution</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaY5"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmY5"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateY5" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vY5"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cY5"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Yellow 6 Stock Solution</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaY6"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmY6"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateY6" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vY6"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cY6"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Deionized Water</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaH2O"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmH2O"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateH2O" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dH2O"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <v-select
        v-model="inputs.equipment"
        :items="equipmentOptions"
        multiple
        chips
        class="ml-6 mb-n4 mt-0"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LCFS2IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaR40: null,
        mmR40: null,
        physStateR40: null,
        vR40: null,
        cR40: null,
        chemFormulaB1: null,
        mmB1: null,
        physStateB1: null,
        vB1: null,
        cB1: null,
        chemFormulaY5: null,
        mmY5: null,
        physStateY5: null,
        vY5: null,
        cY5: null,
        chemFormulaY6: null,
        mmY6: null,
        physStateY6: null,
        vY6: null,
        cY6: null,
        chemFormulaH2O: null,
        mmH2O: null,
        physStateH2O: null,
        dH2O: null,
        equipment: [],
      },
      headings: [
        {text: 'Chemical Name'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Physical States'},
        {text: 'Total Volume Used (mL)'},
        {text: 'Concentration (M)'},
        {text: 'density (g/mL)'},
      ],
      stateOptions: ['l', 's', 'g', 'aq'],
      equipmentOptions: [
        'beaker(s)',
        'Buchner funnel',
        'buret',
        'capillary/melting tube',
        'clamp and stand',
        'cuvette',
        'Digi-Melt (melting point apparatus)',
        'digital balance',
        'disposable pipet',
        'drying oven',
        'Erlenmeyer flask',
        'filter paper',
        'funnel',
        'graduated cylinder',
        'ice',
        'KimWipes (tissues)',
        'LabQuest',
        'pencil',
        'pipet suction bulb',
        'probe (pH)',
        'probe (temperature)',
        'scintillation vial',
        'side arm flask',
        'spatula or scoopula',
        'spectrometer',
        'spotter',
        'stir bar',
        'stirrer/hotplate',
        'test tube',
        'thermometer (alcohol)',
        'TLC (developing) chamber',
        'TLC plate',
        'TLC spotter',
        'UV lamp',
        'volumetric flask',
        'volumetric pipet',
        'watch glass',
        'weighing boat or paper',
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
