














import {computed, defineComponent, PropType, ref} from '@vue/composition-api';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import {useAuthService} from '@/auth/services/authService';
import i18n from '@/locales/composables/i18n';
import {en as enComponentTranslations} from '@/locales/en/components';
import {fr as frComponentTranslations} from '@/locales/fr/components';

export default defineComponent({
  name: 'MultiSelectUiComponent',
  components: {DragToRankInput, StembleLatex, ChemicalLatex},
  props: {
    prependText: {
      type: String as PropType<string>,
      default: null,
    },
    appendText: {
      type: String as PropType<string>,
      default: null,
    },
    options: {
      type: Array as PropType<{key: string; label: string}[]>,
      default: () => [],
    },
    selectedOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: {
    'update:selectedOptions': (options: (string | number)[]) => {},
  },
  created() {
    i18n.mergeLocaleMessage('en', enComponentTranslations);
    i18n.mergeLocaleMessage('fr', frComponentTranslations);
  },
  setup(props, {emit}) {
    const auth = useAuthService();
    const clonedSelectedOptions = ref([...(props.selectedOptions || [])]);

    function syncSelected() {
      emit('update:selectedOptions', clonedSelectedOptions.value);
    }

    const randomizedItems = computed(() => {
      const labels = props.options.map(({label}) => label);

      // We shuffle the correct answers as they come back so that
      // people don't immediately recognize the correct order
      return seededShuffle(labels, auth.user?.id ?? 1);
    });

    return {clonedSelectedOptions, randomizedItems, syncSelected};
  },
});
