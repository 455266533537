var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Enter appropriate identifying information for each chemical used during Fundamental Skills. ")]),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headings),function(head){return _c('th',{key:head.text},[_vm._v(" "+_vm._s(head.text)+" ")])}),0)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Crystal Violet Solution")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaCV),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaCV", $$v)},expression:"inputs.chemFormulaCV"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmCV),callback:function ($$v) {_vm.$set(_vm.inputs, "mmCV", $$v)},expression:"inputs.mmCV"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateCV),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateCV", $$v)},expression:"inputs.physStateCV"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vCV),callback:function ($$v) {_vm.$set(_vm.inputs, "vCV", $$v)},expression:"inputs.vCV"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cCV),callback:function ($$v) {_vm.$set(_vm.inputs, "cCV", $$v)},expression:"inputs.cCV"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Sodium Hydroxide Stock Solution")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaStock),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaStock", $$v)},expression:"inputs.chemFormulaStock"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmStock),callback:function ($$v) {_vm.$set(_vm.inputs, "mmStock", $$v)},expression:"inputs.mmStock"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateStock),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateStock", $$v)},expression:"inputs.physStateStock"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vStock),callback:function ($$v) {_vm.$set(_vm.inputs, "vStock", $$v)},expression:"inputs.vStock"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cStock),callback:function ($$v) {_vm.$set(_vm.inputs, "cStock", $$v)},expression:"inputs.cStock"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Sodium Hydroxide Solution #1 (most concentrated)")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaSol1),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaSol1", $$v)},expression:"inputs.chemFormulaSol1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmSol1),callback:function ($$v) {_vm.$set(_vm.inputs, "mmSol1", $$v)},expression:"inputs.mmSol1"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSol1),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSol1", $$v)},expression:"inputs.physStateSol1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSol1),callback:function ($$v) {_vm.$set(_vm.inputs, "vSol1", $$v)},expression:"inputs.vSol1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSol1),callback:function ($$v) {_vm.$set(_vm.inputs, "cSol1", $$v)},expression:"inputs.cSol1"}})],1),_c('td',{staticStyle:{"width":"250px"}},[_c('v-select',{attrs:{"items":_vm.sourceOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.sourceSol1),callback:function ($$v) {_vm.$set(_vm.inputs, "sourceSol1", $$v)},expression:"inputs.sourceSol1"}})],1)]),_c('tr',[_c('td',[_vm._v("Sodium Hydroxide Solution #2")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaSol2),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaSol2", $$v)},expression:"inputs.chemFormulaSol2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmSol2),callback:function ($$v) {_vm.$set(_vm.inputs, "mmSol2", $$v)},expression:"inputs.mmSol2"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSol2),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSol2", $$v)},expression:"inputs.physStateSol2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSol2),callback:function ($$v) {_vm.$set(_vm.inputs, "vSol2", $$v)},expression:"inputs.vSol2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSol2),callback:function ($$v) {_vm.$set(_vm.inputs, "cSol2", $$v)},expression:"inputs.cSol2"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.sourceOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.sourceSol2),callback:function ($$v) {_vm.$set(_vm.inputs, "sourceSol2", $$v)},expression:"inputs.sourceSol2"}})],1)]),_c('tr',[_c('td',[_vm._v("Sodium Hydroxide Solution #3 (most dilute)")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaSol3),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaSol3", $$v)},expression:"inputs.chemFormulaSol3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmSol3),callback:function ($$v) {_vm.$set(_vm.inputs, "mmSol3", $$v)},expression:"inputs.mmSol3"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSol3),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSol3", $$v)},expression:"inputs.physStateSol3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSol3),callback:function ($$v) {_vm.$set(_vm.inputs, "vSol3", $$v)},expression:"inputs.vSol3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSol3),callback:function ($$v) {_vm.$set(_vm.inputs, "cSol3", $$v)},expression:"inputs.cSol3"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.sourceOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.sourceSol3),callback:function ($$v) {_vm.$set(_vm.inputs, "sourceSol3", $$v)},expression:"inputs.sourceSol3"}})],1)]),_c('tr',[_c('td',[_vm._v("Deionized Water")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaH2O", $$v)},expression:"inputs.chemFormulaH2O"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "mmH2O", $$v)},expression:"inputs.mmH2O"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateH2O", $$v)},expression:"inputs.physStateH2O"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")])])])]),_c('p',{staticClass:"mt-5"},[_vm._v("Select all the glassware/equipment/instrumentation used in this lab.")]),_c('v-select',{staticClass:"ml-6 mb-n4 mt-0",attrs:{"items":_vm.equipmentOptions,"multiple":"","chips":""},model:{value:(_vm.inputs.equipment),callback:function ($$v) {_vm.$set(_vm.inputs, "equipment", $$v)},expression:"inputs.equipment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }