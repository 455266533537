var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Enter appropriate identifying information for each chemical used during Fundamental Skills. (Recalculate actual values based on what is weighed out in lab for in-lab and lab report calculations.) ")]),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headings),function(head){return _c('th',{key:head.text},[_vm._v(" "+_vm._s(head.text)+" ")])}),0)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Menthol")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaMenth),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaMenth", $$v)},expression:"inputs.chemFormulaMenth"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmMenth),callback:function ($$v) {_vm.$set(_vm.inputs, "mmMenth", $$v)},expression:"inputs.mmMenth"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateMenth),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateMenth", $$v)},expression:"inputs.physStateMenth"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mMenth),callback:function ($$v) {_vm.$set(_vm.inputs, "mMenth", $$v)},expression:"inputs.mMenth"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molMenth),callback:function ($$v) {_vm.$set(_vm.inputs, "molMenth", $$v)},expression:"inputs.molMenth"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpMenth),callback:function ($$v) {_vm.$set(_vm.inputs, "mpMenth", $$v)},expression:"inputs.mpMenth"}})],1)]),_c('tr',[_c('td',[_vm._v("Cinnamic Acid")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaCA),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaCA", $$v)},expression:"inputs.chemFormulaCA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmCA),callback:function ($$v) {_vm.$set(_vm.inputs, "mmCA", $$v)},expression:"inputs.mmCA"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateCA),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateCA", $$v)},expression:"inputs.physStateCA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mCA),callback:function ($$v) {_vm.$set(_vm.inputs, "mCA", $$v)},expression:"inputs.mCA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molCA),callback:function ($$v) {_vm.$set(_vm.inputs, "molCA", $$v)},expression:"inputs.molCA"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpCA),callback:function ($$v) {_vm.$set(_vm.inputs, "mpCA", $$v)},expression:"inputs.mpCA"}})],1)]),_c('tr',[_c('td',[_vm._v("Vanillin")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaVan),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaVan", $$v)},expression:"inputs.chemFormulaVan"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmVan),callback:function ($$v) {_vm.$set(_vm.inputs, "mmVan", $$v)},expression:"inputs.mmVan"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateVan),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateVan", $$v)},expression:"inputs.physStateVan"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mVan),callback:function ($$v) {_vm.$set(_vm.inputs, "mVan", $$v)},expression:"inputs.mVan"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molVan),callback:function ($$v) {_vm.$set(_vm.inputs, "molVan", $$v)},expression:"inputs.molVan"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpVan),callback:function ($$v) {_vm.$set(_vm.inputs, "mpVan", $$v)},expression:"inputs.mpVan"}})],1)]),_c('tr',[_c('td',[_vm._v("4-hydroxybenzaldehyde")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaHB),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaHB", $$v)},expression:"inputs.chemFormulaHB"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmHB),callback:function ($$v) {_vm.$set(_vm.inputs, "mmHB", $$v)},expression:"inputs.mmHB"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateHB),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateHB", $$v)},expression:"inputs.physStateHB"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mHB),callback:function ($$v) {_vm.$set(_vm.inputs, "mHB", $$v)},expression:"inputs.mHB"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molHB),callback:function ($$v) {_vm.$set(_vm.inputs, "molHB", $$v)},expression:"inputs.molHB"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mpHB),callback:function ($$v) {_vm.$set(_vm.inputs, "mpHB", $$v)},expression:"inputs.mpHB"}})],1)])])]),_c('p',{staticClass:"mt-5"},[_vm._v("Select all the glassware/equipment/instrumentation used in this lab.")]),_c('v-select',{staticClass:"ml-6 mb-n4 mt-0",attrs:{"items":_vm.equipmentOptions,"multiple":"","chips":""},model:{value:(_vm.inputs.equipment),callback:function ($$v) {_vm.$set(_vm.inputs, "equipment", $$v)},expression:"inputs.equipment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }