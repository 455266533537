import { render, staticRenderFns } from "./ThinLayerChromatographySIM.vue?vue&type=template&id=c7ab3d38&"
import script from "./ThinLayerChromatographySIM.vue?vue&type=script&lang=ts&"
export * from "./ThinLayerChromatographySIM.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports