<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        An unidentified weak acid,
        <chemical-latex content="HA," />
        has a
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of
        <number-value :value="pKa" />. A
        <stemble-latex content="$30.0\,\text{mL}$" />
        sample of
        <number-value :value="concHA" unit="\text{M HA}" />
        is titrated with
        <number-value :value="concNaOH" unit="\text{M NaOH.}" />
        Determine the resulting
        <stemble-latex content="$\text{pH}$" />
        after the additions of each of the following amounts of titrant.
      </p>

      <p class="mb-2">
        <stemble-latex content="$\text{a) 15.00 mL of NaOH}$" />
      </p>

      <calculation-input
        v-model="inputs.pHa"
        class="mb-5"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{b) 45.00 mL of NaOH}$" />
      </p>

      <calculation-input
        v-model="inputs.pHb"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question168',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pHa: null,
        pHb: null,
      },
    };
  },
  computed: {
    concHA() {
      return this.taskState.getValueBySymbol('concHA').content;
    },
    concNaOH() {
      return this.taskState.getValueBySymbol('concNaOH').content;
    },
    pKa() {
      return this.taskState.getValueBySymbol('pKa').content;
    },
  },
};
</script>
