
















































































import {defineComponent, PropType, reactive, toRef} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {useMcMasterLab3SlopeIntercept} from '@/tasks/composables/UseMcMasterLab3SlopeIntercept';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

interface TableRow {
  volume: number | null;
  conc: number | null;
  concB: number | null;
  abs: number | null;
}

export default defineComponent({
  name: 'McMasterLab3ResultsPartA',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    AssistedGradingWritten,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const inputs = reactive({
      rows: [
        {volume: null, conc: null, concB: null, abs: null},
        {volume: null, conc: null, concB: null, abs: null},
        {volume: null, conc: null, concB: null, abs: null},
      ] as TableRow[],
    });
    const rows = toRef(inputs, 'rows');
    const {colDefs, equation, apexSeries, apexOptions} = useMcMasterLab3SlopeIntercept({
      labels: {abs: 'Absorbance', concB: ''},
      x: 'concB',
      y: 'abs',
      rows,
      isMarking: props.isMarking,
      linearEquationY: 'A',
      linearEquationX: '[C]',
      minX: 0.0,
      maxX: null,
      minY: 0.0,
      maxY: null,
    });

    return {
      calibrationCurveColDefs: colDefs,
      beersLaw: equation,
      rows,
      inputs,
      apexOptions,
      apexSeries,
    };
  },
});
