















































































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import TableInput from '@/tasks/components/inputs/TableInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab2Results',
  components: {TableInput, ChemicalLatex, CalculationInput, StembleLatex, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        massCrude: null,
        massProduct: null,
        appearance: null,
        mpCrudeProduct1: null,
        mpCrudeProduct2: null,
        mpRecrystallizedProduct1: null,
        mpRecrystallizedProduct2: null,
        pctYield: null,
        sampleCalc: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
    };
  },
});
