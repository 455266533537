<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical involved in the synthesis
        procedure. This type of table is more representative of what will be required in organic
        chemistry lab, where solvents are typically omitted. (Note: Recalculate actual values based
        on what is weighed out in lab for in-lab and lab report calculations.)
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings1" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vanillin</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaVan"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Acetic Acid</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaAcA"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.unitcAcA" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molAcA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Hydrogen Peroxide</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaH2O2"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmH2O2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cH2O2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.unitcH2O2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>

            <td>N/A</td>
          </tr>
          <tr>
            <td>Horseradish Peroxidase</td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vHP"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cHP"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.unitcHP" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>

      <table class="mt-10">
        <thead>
          <tr>
            <th v-for="head in headings2" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vanillic Acid</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaVA"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmVA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.yieldVA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Divanillin (dehydrodivanillin)</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaDV"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmDV"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.yieldDV"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <v-select
        v-model="inputs.equipment"
        :items="equipmentOptions"
        multiple
        chips
        class="ml-6 mb-n4 mt-0"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LDFS3IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaVan: null,
        mmVan: null,
        mVan: null,
        molVan: null,

        chemFormulaAcA: null,
        mmAcA: null,
        vAcA: null,
        cAcA: null,
        unitcAcA: null,
        molAcA: null,

        chemFormulaH2O2: null,
        mmH2O2: null,
        vH2O2: null,
        cH2O2: null,
        unitcH2O2: null,

        vHP: null,
        cHP: null,
        unitcHP: null,

        chemFormulaVA: null,
        mmVA: null,
        yieldVA: null,

        chemFormulaDV: null,
        mmDV: null,
        yieldDV: null,

        equipment: [],
      },
      headings1: [
        {text: 'Reactant'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Volume (mL)'},
        {text: 'Mass (g)'},
        {text: 'Concentration Value'},
        {text: 'Concentration Unit'},
        {text: 'Moles (mol)'},
      ],
      headings2: [
        {text: 'Possible Product'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Theoretical Yield (g)'},
      ],
      unitOptions: ['mg/mL', 'M', '%'],
      equipmentOptions: [
        'beaker(s)',
        'Buchner funnel',
        'buret',
        'capillary/melting tube',
        'clamp and stand',
        'cuvette',
        'Digi-Melt (melting point apparatus)',
        'digital balance',
        'disposable pipet',
        'drying oven',
        'Erlenmeyer flask',
        'filter paper',
        'funnel',
        'graduated cylinder',
        'ice',
        'KimWipes (tissues)',
        'LabQuest',
        'pencil',
        'pipet suction bulb',
        'probe (pH)',
        'probe (temperature)',
        'scintillation vial',
        'side arm flask',
        'spatula or scoopula',
        'spectrometer',
        'spotter',
        'stir bar',
        'stirrer/hotplate',
        'test tube',
        'thermometer (alcohol)',
        'TLC (developing) chamber',
        'TLC plate',
        'TLC spotter',
        'UV lamp',
        'volumetric flask',
        'volumetric pipet',
        'watch glass',
        'weighing boat or paper',
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
