<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine the amount, in mol, of each compound present in the sample based on the mass and
        molecular formula provided:
      </p>

      <p class="mb-2">
        <stemble-latex content="$\text{a) }$" />
        <number-value :value="massA" unit="\text{g}" /> of the amino acid glycine,
        <stemble-latex content="$\ce{C2H5NO2}$" />
      </p>
      <calculation-input
        v-model="inputs.molA"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{b) }$" />
        <number-value :value="massB" unit="\text{kg}" /> of the insecticide Paris Green,
        <stemble-latex content="$\ce{Cu4(AsO2)6(CH3CO2)2}$" />
      </p>
      <calculation-input
        v-model="inputs.molB"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        <small
          ><b>Helpful tip:</b> To enter a number in scientific notation, enter 1.0E5 or 1.0e5, for
          1.0 x 10<sup>5</sup> where the E# (or e#) denotes 10<sup>#</sup></small
        >
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question219',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molA: null,
        molB: null,
      },
    };
  },
  computed: {
    massA() {
      return this.taskState.getValueBySymbol('massA').content;
    },
    massB() {
      return this.taskState.getValueBySymbol('massB').content;
    },
  },
};
</script>
