<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Academic Integrity Agreement</h2>

      <p class="mb-2">
        <b
          >By submitting this work, I confirm that I did attend this experiment and that all data
          entered is my own.</b
        >
        Please write your name and select the appropriate date in the spaces below. This will serve
        as a replacement for a date and signature to indicate that you will agree to the statements
        above.
      </p>

      <v-simple-table style="max-width: 650px" class="pl-6 mb-5">
        <tr dense>
          <td style="width: 85px; font-weight: bold">Name:</td>
          <td>
            <v-text-field v-model="inputs.name" />
          </td>
        </tr>
        <tr dense>
          <td style="width: 85px; font-weight: bold">Date:</td>
          <td>
            <input v-model="inputs.signingDate" type="date" />
          </td>
        </tr>
      </v-simple-table>

      <p>
        Please upload a scanned copy (as a .pdf) of all your laboratory notebook pages that pertain
        to this experiment, including your flowchart. These pages must clearly show your name and
        the signature and date stamp of your teaching assistant.
      </p>

      <p class="mb-3">
        <v-file-input v-model="attachments" multiple accept="application/pdf" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'McMasterAcademicIntegrity',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        name: null,
        signingDate: null,
      },
      options: [{text: 'I agree', value: 'agree'}],
      attachments: [],
    };
  },
  computed: {
    today() {
      return new Date().toJSON().slice(0, 10);
    },
  },
};
</script>
