var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Quantitative Volumetric Analysis: Results")]),_c('p',{staticClass:"mb-2"},[_vm._v(" a) Fill in the data table below using the data you collected during the experiment and any associated calculations. ")]),_c('p',{staticClass:"pl-n8 mb-5"},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',{staticStyle:{"width":"25%"}}),_c('td',{staticStyle:{"width":"25%","text-align":"center","font-weight":"bold"}},[_vm._v("Trial 1")]),_c('td',{staticStyle:{"width":"25%","text-align":"center","font-weight":"bold"}},[_vm._v("Trial 2")]),_c('td',{staticStyle:{"width":"25%","text-align":"center","font-weight":"bold"}},[_vm._v("Trial 3")])]),_vm._l((_vm.labResults),function(result){return _c('tr',{key:result.value},[_c('td',[_c('stemble-latex',{attrs:{"content":result.property}})],1),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('calculation-input',{model:{value:(_vm.inputs[result.value1]),callback:function ($$v) {_vm.$set(_vm.inputs, result.value1, $$v)},expression:"inputs[result.value1]"}})],1),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('calculation-input',{model:{value:(_vm.inputs[result.value2]),callback:function ($$v) {_vm.$set(_vm.inputs, result.value2, $$v)},expression:"inputs[result.value2]"}})],1),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('calculation-input',{model:{value:(_vm.inputs[result.value3]),callback:function ($$v) {_vm.$set(_vm.inputs, result.value3, $$v)},expression:"inputs[result.value3]"}})],1)])}),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"Colour of solution after titration"}})],1),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('v-select',{attrs:{"items":_vm.options,"label":"Select colour:","item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.colour1),callback:function ($$v) {_vm.$set(_vm.inputs, "colour1", $$v)},expression:"inputs.colour1"}})],1),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('v-select',{attrs:{"items":_vm.options,"label":"Select colour:","item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.colour2),callback:function ($$v) {_vm.$set(_vm.inputs, "colour2", $$v)},expression:"inputs.colour2"}})],1),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('v-select',{attrs:{"items":_vm.options,"label":"Select colour:","item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.colour3),callback:function ($$v) {_vm.$set(_vm.inputs, "colour3", $$v)},expression:"inputs.colour3"}})],1)])],2)])],1),_c('p',{staticClass:"mb-2 mt-4"},[_vm._v("b) Fill in the table below to summarize the results of the experiment.")]),_c('v-simple-table',{staticStyle:{"max-width":"450px"},attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('td',[_vm._v("Average Molarity of NaOH (M)")]),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('calculation-input',{model:{value:(_vm.inputs.concNaOHAvg),callback:function ($$v) {_vm.$set(_vm.inputs, "concNaOHAvg", $$v)},expression:"inputs.concNaOHAvg"}})],1)]),_c('tr',[_c('td',[_vm._v("Actual Molarity of NaOH (M)")]),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('calculation-input',{model:{value:(_vm.inputs.trueConcNaOH),callback:function ($$v) {_vm.$set(_vm.inputs, "trueConcNaOH", $$v)},expression:"inputs.trueConcNaOH"}})],1)]),_c('tr',[_c('td',[_vm._v("Percent Error (%)")]),_c('td',{staticClass:"centered-input py-2 px-2 mx-0"},[_c('calculation-input',{model:{value:(_vm.inputs.pctError),callback:function ($$v) {_vm.$set(_vm.inputs, "pctError", $$v)},expression:"inputs.pctError"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }