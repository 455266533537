<template>
  <div style="width: 100%">
    <!-- Task Content -->
    <content-area v-bind="$attrs" :task="task" />
    <v-divider />
    <v-form @submit.prevent="submitResponse()">
      <v-row align="stretch" align-items="start">
        <v-col sm="2">
          <span>{{ $t('calculationQuestion.label') }}:</span>
        </v-col>
        <v-col sm="10">
          <calculation-input
            v-model="attempt.answer"
            :state="responseState"
            :disabled="!allowEditing"
            :prepend-text="prependText"
            :append-text="appendText"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ContentArea from '@/tasks/components/ContentArea';
import swal from 'sweetalert2';
import {TaskCardMixin} from '../mixins/task-card';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import {temporarySettingResponseFlag} from '@/tasks/utils/temporarySettingResponseFlag';
import i18n from '@/locales/composables/i18n';
import {en as enComponentTranslations} from '@/locales/en/components';
import {fr as frComponentTranslations} from '@/locales/fr/components';

export default {
  name: 'CalculationQuestion',
  mixins: [TaskCardMixin()],
  components: {
    ContentArea,
    CalculationInput,
  },
  created() {
    i18n.mergeLocaleMessage('en', enComponentTranslations);
    i18n.mergeLocaleMessage('fr', frComponentTranslations);
  },
  data() {
    return {
      isSettingResponse: false,
      previousResponse: null,
      attempt: {
        answer: null,
      },
    };
  },
  computed: {
    responseState() {
      return this.isNotSameAnswer ? null : this.isCorrect;
    },
    validNumber() {
      if (this.attempt.answer === null) {
        return false;
      }
      return this.attempt.answer.trim() ? !isNaN(this.attempt.answer.trim()) : false;
    },
    user() {
      return this.$auth.user;
    },
    isAnswerSelected() {
      return (
        this.attempt.answer !== '' &&
        this.attempt.answer !== null &&
        this.attempt.answer !== undefined
      );
    },
    prependText() {
      return this.task.content.prependText;
    },
    appendText() {
      return this.task.content.appendText;
    },
    isTryAgain() {
      return this.previousResponse !== null;
    },
    isNotSameAnswer() {
      if (this.isTryAgain) {
        return this.previousResponse !== this.attempt.answer;
      } else {
        return true;
      }
    },
  },
  watch: {
    attempt: {
      deep: true,
      handler() {
        if (this.isSettingResponse) {
          return;
        }
        this.$emit('update:inputData', this.attempt);
      },
    },
  },
  methods: {
    submitResponse() {
      if (this.validNumber) {
        let response = {
          answer: this.attempt.answer,
        };
        this.$emit('submit-response', {response});
      } else if (this.attempt.answer === null) {
        swal({
          text: this.$t('calculationQuestion.dataRequiredError'),
          type: 'error',
        });
      } else {
        swal({
          text: this.$t('calculationQuestion.invalidInputError'),
          type: 'error',
        });
      }
    },
    setResponse(response) {
      temporarySettingResponseFlag(
        (v) => (this.isSettingResponse = v),
        () => {
          this.attempt.answer = response.content.stringAnswer;
          this.previousResponse = response.content.stringAnswer;
        }
      );
    },
    clearResponse() {
      temporarySettingResponseFlag(
        (v) => (this.isSettingResponse = v),
        () => {
          this.attempt.answer = null;
          this.previousResponse = null;
        }
      );
    },
  },
};
</script>
