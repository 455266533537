<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Electrochemistry Experiment: Results - Part A</h2>

      <p class="mb-5">
        a) Fill in the measured voltage for the <b>voltaic</b> cell generated from each combination
        of half-cells in the table below. As the electrodes are unique for each half-cell, the
        half-cell will be denoted simply by the material used for the electrode.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Cell}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Half-Cell 1}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2">
              <stemble-latex content="$\text{Half-Cell 2}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2" style="text-align: center">
              <stemble-latex content="$\text{E}_\text{cell, exp}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2" style="text-align: center">
              <stemble-latex content="$\text{E}_\text{cell, theor}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2" style="text-align: center">
              <stemble-latex content="$\%\,\text{Error}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$1$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Cu(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Pb(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$2$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Cu(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Mg(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$3$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Cu(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Ni(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$4$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Cu(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Sn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$5$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Cu(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Zn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell5"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical5"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError5"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$6$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Pb(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Mg(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell6"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical6"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError6"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$7$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Pb(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Ni(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell7"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical7"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError7"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$8$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Pb(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Sn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell8"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical8"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError8"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$9$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Pb(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Zn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell9"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical9"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError9"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$10$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Mg(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Ni(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell10"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical10"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError10"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$11$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Mg(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Sn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell11"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical11"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError11"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$12$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Mg(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Zn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell12"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical12"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError12"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$13$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Ni(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Sn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell13"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical13"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError13"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$14$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Ni(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Zn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell14"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical14"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError14"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$15$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Sn(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Zn(s)}$" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell15"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical15"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError15"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2 mt-5">
        b) Please draw and upload a cell diagram of <b>CELL {{ cellNumber.value }}</b> AND your data
        sheet for this part of the experiment using the upload field below.
      </p>

      <p class="mb-3">
        <v-file-input v-model="attachments" multiple accept="application/pdf" />
      </p>

      <p class="mb-1" style="font-weight: bold">
        Have multiple files to upload? Click
        <a
          href="https://support.stemble.com/attach-multiple-files "
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        to find out how.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'ChemMcMA5_ResultsA',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ecell1: null,
        Ecell2: null,
        Ecell3: null,
        Ecell4: null,
        Ecell5: null,
        Ecell6: null,
        Ecell7: null,
        Ecell8: null,
        Ecell9: null,
        Ecell10: null,
        Ecell11: null,
        Ecell12: null,
        Ecell13: null,
        Ecell14: null,
        Ecell15: null,
        EcellTheoretical1: null,
        EcellTheoretical2: null,
        EcellTheoretical3: null,
        EcellTheoretical4: null,
        EcellTheoretical5: null,
        EcellTheoretical6: null,
        EcellTheoretical7: null,
        EcellTheoretical8: null,
        EcellTheoretical9: null,
        EcellTheoretical10: null,
        EcellTheoretical11: null,
        EcellTheoretical12: null,
        EcellTheoretical13: null,
        EcellTheoretical14: null,
        EcellTheoretical15: null,
        pctError1: null,
        pctError2: null,
        pctError3: null,
        pctError4: null,
        pctError5: null,
        pctError6: null,
        pctError7: null,
        pctError8: null,
        pctError9: null,
        pctError10: null,
        pctError11: null,
        pctError12: null,
        pctError13: null,
        pctError14: null,
        pctError15: null,
      },
      attachments: [],
    };
  },
  computed: {
    cellNumber() {
      return this.taskState.getValueBySymbol('cellNumber').content;
    },
  },
};
</script>
<style scoped></style>
