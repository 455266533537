<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A hypothetical base,
        <chemical-latex content="A^-," />
        has a
        <stemble-latex content="$\text{pK}_\text{b}$" />
        of
        <number-value :value="pKb" />. A
        <stemble-latex content="$50.0\,\text{mL}$" />
        sample of
        <number-value :value="concA" unit="\text{M}~\ce{A-}" />
        is titrated with
        <number-value :value="concHCl" unit="\text{M HCl.}" />
        Calculate the
        <stemble-latex content="$\text{pH}$" />
        of the solution after the additions of each of the following amounts of titrant:
      </p>

      <p class="mb-2">
        <stemble-latex content="$\text{a) 25.00 mL of HCl}$" />
      </p>

      <calculation-input
        v-model="inputs.pHa"
        class="mb-5"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{b) 70.00 mL of HCl}$" />
      </p>

      <calculation-input
        v-model="inputs.pHb"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question167',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pHa: null,
        pHb: null,
      },
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concHCl() {
      return this.taskState.getValueBySymbol('concHCl').content;
    },
    pKb() {
      return this.taskState.getValueBySymbol('pKb').content;
    },
  },
};
</script>
