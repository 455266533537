export const en = {
  fileUploadUiComponent: {
    formattedMaxFileSize: '{num} MB',
    maxFileSize: 'Max file size',
    acceptedFileTypes: 'Accepted File Types',
    fileExceedsLimit: 'Each file must be smaller than {limit}',
    tooManyFiles: 'You can upload a maximum of {num} file(s).',
  },
  tableInput: {
    step: 'Step',
    headerPlaceholder: 'Enter Column Title',
    addRow: 'Add Row',
    addColumn: 'Add Column',
    deleteRow: 'Delete this Row?',
    deleteColumn: 'Delete this Column?',
    newColumn: 'New Column',
  },
  calculationQuestion: {
    label: 'Your answer',
    dataRequiredError: 'You must type in an answer.',
    invalidInputError: 'You must type in a valid number.',
  },
  dragToRank: {
    availableItems: 'Available Items to Sort',
    dragItemsHere: 'Drag your items here to start ranking',
    outOfItems: 'There are no more items left to sort',
  },
  rankOrderUiComponent: {
    smallest: 'Smallest',
    largest: 'Largest',
  },
};
