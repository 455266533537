




















import {defineComponent} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawaVideosDescriptionFr',
  mixins: [DynamicQuestionMixin()],
  components: {StembleLatex},
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          expression: 'I understand',
          value: 'agreed',
        },
      ],
    };
  },
});
