



























import {defineComponent} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'HCCSafetyDataLog',
  mixins: [DynamicQuestionMixin()],
  components: {FileUploadUiComponent, StembleLatex},
  data() {
    return {
      attachments: [] as File[],
    };
  },
});
