<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Your boss has asked you to prepare a solution of an acetate buffer. Unfortunately, you only
        have a bottle of
        <number-value :value="acidConc" unit="\text{M}" />
        of acetic acid,
        <chemical-latex content="CH3COOH," />
        and a bottle of
        <stemble-latex content="$0.200\,\text{M}$" />
        of
        <chemical-latex content="NaOH\text{.}" />
        Note that the
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of
        <chemical-latex content="CH3COOH" />
        is
        <stemble-latex content="$4.74.$" />
      </p>

      <p class="mb-2">
        a) How many <b>moles</b>
        of
        <chemical-latex content="CH3COOH" />
        are in
        <stemble-latex content="$100.0~\text{mL}$" />
        of a
        <number-value :value="acidConc" unit="\text{M}" />
        solution of acetic acid?
      </p>

      <calculation-input
        v-model="inputs.molCH3COOH"
        class="mb-5"
        prepend-text="$\ce{CH3COOH}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) How many <b>moles</b>
        of
        <chemical-latex content="NaOH" />
        must you add to the
        <stemble-latex content="$100.0~\text{mL}$" />
        <number-value :value="acidConc" unit="\text{M}" />
        acetic acid to obtain a buffer solution with a
        <stemble-latex content="$\text{pH}$" />
        of
        <number-value :value="requiredpH" />?
      </p>

      <calculation-input
        v-model="inputs.molNaOH"
        class="mb-5"
        prepend-text="$\ce{NaOH}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What volume of
        <stemble-latex content="$0.200~\text{M NaOH}$" />
        must you add, in
        <stemble-latex content="$\textbf{mL},$" />
        to reach that
        <stemble-latex content="$\text{pH?}$" />
      </p>

      <calculation-input
        v-model="inputs.volNaOH"
        prepend-text="$\ce{V}:$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question160',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molCH3COOH: null,
        molNaOH: null,
        volNaOH: null,
      },
    };
  },
  computed: {
    acidConc() {
      return this.taskState.getValueBySymbol('acidConc').content;
    },
    requiredpH() {
      return this.taskState.getValueBySymbol('requiredpH').content;
    },
  },
};
</script>
