<template>
  <v-form @submit.prevent="submitResponse">
    <h2 v-if="task.content.config.heading" class="mb-4">
      <stemble-latex :content="task.content.config.heading" style="white-space: pre-wrap" />
    </h2>
    <div
      v-for="(question, index) in task.content.config.questions"
      :key="question.questionText"
      style="display: flex; flex-direction: column"
    >
      <label :for="question.questionText" class="body-1 v-label theme--light mb-3">
        <stemble-latex :content="question.questionText" style="white-space: pre-wrap" />
      </label>
      <s-textarea
        :id="question.questionText"
        v-model="inputs['answer' + index]"
        outlined
        :counter="question.characterLimit > 0"
        :maxlength="question.characterLimit > 0 ? question.characterLimit : undefined"
      />
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'AiAllPurposeQuestion',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    const inputs = {};
    this.task.content.config.questions.forEach((question, index) => {
      inputs['answer' + index] = '';
    });
    return {
      inputs,
    };
  },
};
</script>
