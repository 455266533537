<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the percent by mass of water in
        <chemical-latex :content="hydrateFormula" />
        to 3 significant figures.
      </p>

      <calculation-input
        v-model="inputs.percentWater"
        class="mb-5"
        prepend-text="$\text{Water:}$"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question221',
  components: {
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        percentWater: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    hydrateFormula() {
      if (this.versionVariable.value === 2) {
        return 'CuSO4.5H2O';
      } else if (this.versionVariable.value === 3) {
        return 'CaSO4.2H2O';
      } else if (this.versionVariable.value === 1) {
        return 'CuCl2.2H2O';
      } else {
        return '';
      }
    },
  },
};
</script>
