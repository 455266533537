<template>
  <div style="text-align: justify">
    <p class="mb-4" style="font-weight: bold; color: #c50000">
      NOTE: If you are using a prelab that does not have "STEMBLE VERSION" written at the top, you
      may encounter an error here. Please ignore the error and/or any data that does not align with
      your hard copy of the prelab. Use the data from your hard copy to answer the question.
    </p>

    <p class="mb-3">
      The concentration of a solution of iron(II) sulfate,
      <chemical-latex content="FeSO4," />
      can be determined through a redox titration. A
      <latex-number number="50.00" unit="\text{mL}" />
      sample of the solution is diluted to
      <latex-number number="250.00" unit="\text{mL}" />
      with deionized water. A
      <latex-number number="25.00" unit="\text{mL}" />
      aliquot is then pipetted into an Erlenmeyer where it is acidified with sulfuric acid and then
      titrated with a standard solution of potassium dichromate,
      <chemical-latex content="K2Cr2O7\text{.}" />
      The reactants and products of the reaction (unbalanced) are as follows:
    </p>

    <p class="mb-6 pl-10">
      <chemical-latex content="Cr2O7^{2-}(aq) + Fe^{2+}(aq) -> Cr^{3+}(aq) + Fe^{3+}(aq)" />
    </p>

    <p class="mb-3">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-6"
      prepend-text="$\text{Exercise Number:}$"
      :disabled="!allowEditing"
    />

    <p class="mb-6">
      a) In the spaces provided below, write the balanced half-reactions as well as the overall
      balanced redox equation for the reaction between the dichromate ion and iron(II) ion in
      <b>acidic solution</b>.
    </p>

    <chemical-notation-input
      v-model="inputs.oxidationRxn"
      dense
      label="Oxidation half-reaction"
      :show-note="false"
      class="mb-3"
      :disabled="!allowEditing"
    />

    <chemical-notation-input
      v-model="inputs.reductionRxn"
      dense
      label="Reduction half-reaction"
      :show-note="false"
      class="mb-3"
      :disabled="!allowEditing"
    />

    <chemical-notation-input
      v-model="inputs.overallRxn"
      dense
      label="Overall reaction"
      :show-note="false"
      class="mb-7"
      :disabled="!allowEditing"
    />

    <p class="mb-6">
      b) Using the data provided here (and above), calculate the molarity of the original iron
      sulfate solution.
    </p>

    <v-simple-table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">Volume of original sample</td>
          <td>
            <latex-number number="50.00" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">Volume of diluted solution</td>
          <td>
            <latex-number number="250.00" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">Volume of diluted sample titrated</td>
          <td>
            <latex-number number="25.00" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            Standard dichromate solution, <stemble-latex content="$\ce{[Cr2O7^2-]}$" />
          </td>
          <td v-if="exNumber >= 1 && exNumber <= 900" style="vertical-align: middle">
            <latex-number :number="concCr2O7.toFixed(5)" unit="\text{M}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle">Initial burette reading</td>
          <td v-if="exNumber >= 1 && exNumber <= 900" style="vertical-align: middle">
            <latex-number :number="volInit.toFixed(2)" unit="\text{mL}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle">Final burette reading</td>
          <td v-if="exNumber >= 1 && exNumber <= 900" style="vertical-align: middle">
            <latex-number :number="volFinal" unit="\text{mL}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
      </tbody>
    </v-simple-table>

    <calculation-input
      v-model="inputs.concFeSO4"
      prepend-text="$\ce{[FeSO4]}:$"
      append-text="$\text{M}$"
      dense
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'UPEIRedoxPrelabV2',
  components: {
    ChemicalNotationInput,
    STextarea,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        oxidationRxn: null,
        reductionRxn: null,
        overallRxn: null,
        concFeSO4: null,
      },
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    concCr2O7() {
      return seededRandomBetween(this.exNumber, 0.042, 0.044, 5);
    },
    volInit() {
      return seededRandomBetween(this.exNumber * 2, 0.01, 1.0, 2);
    },
    concFeSO4init() {
      return seededRandomBetween(this.exNumber * 3, 1.0, 1.25, 3);
    },
    concFeSO4dil() {
      return (this.concFeSO4init * 50) / 250;
    },
    molFeDil() {
      return this.concFeSO4dil * 0.025;
    },
    molCr2O7() {
      return this.molFeDil / 6;
    },
    volTot() {
      return (this.molCr2O7 / this.concCr2O7) * 1000;
    },
    volFinal() {
      return (this.volTot + this.volInit).toFixed(2);
    },
  },
};
</script>
