






















































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab1Question3',
  components: {FileUploadUiComponent, TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        aiQuestionResponse: null,
        language: 'en',
      },
      questions: [
        {
          en: 'A student is monitoring a reaction involving compounds A and B using TLC. Compound A has an Rf value of 0.45; compound B has an Rf of 0.68.',
          fr: 'Un étudiant suit l’avancement d’une réaction par CCM impliquant les composes A et B. Dans le système de solvant utilisé les valeurs Rf sont : Rf(A) = 0.45 ; Rf(B) = 0.68.',
        },
        {
          en: 'a) Draw and upload a picture of the TLC plate at the beginning of the reaction using the upload field below.',
          fr: 'a) Dessinez un schéma de la plaque CCM au début de la réaction en utilisant le champ de téléchargement ci-dessous.',
        },
        {
          en: 'b) Draw and upload a picture of the TLC plate after 50% completion (50% of the A molecules have been converted to B molecules) using the upload field below.',
          fr: 'b) Dessinez et téléchargez  un schéma de la plaque CCM lorsque la réaction est à 50% (50% de A est converti en B) en utilisant le champ de téléchargement ci-dessous.',
        },
        {
          en: 'c) Draw and upload a picture of the TLC plate at the end of the reaction (all of the A molecules have been converted to B molecules using the upload field below.',
          fr: 'c) Dessinez et téléchargez un schéma de la plaque CCM à la fin de la réaction (toutes les molécules de A ont été converties en B) en utilisant le champ de téléchargement ci-dessous.',
        },
        {
          en: 'd) Why is it better to use a sample of molecule A rather than molecule B to follow the reaction?',
          fr: 'd) Pourquoi est-il préférable d’utiliser A plutôt que B comme référence ?',
        },
      ],
      partAImage: [] as File[],
      partBImage: [] as File[],
      partCImage: [] as File[],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.partAImage, ...this.partBImage, ...this.partCImage];
    },
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
