<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
        (Recalculate actual values based on what is weighed out in lab for in-lab and lab report
        calculations.)
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Menthol</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaMenth"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmMenth"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateMenth" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mMenth"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molMenth"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpMenth"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Cinnamic Acid</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaCA"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmCA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateCA" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mCA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molCA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpCA"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Vanillin</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaVan"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateVan" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpVan"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>4-hydroxybenzaldehyde</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaHB"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmHB"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateHB" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mHB"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molHB"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mpHB"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <v-select
        v-model="inputs.equipment"
        :items="equipmentOptions"
        multiple
        chips
        class="ml-6 mb-n4 mt-0"
      >
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LDFS1IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaMenth: null,
        mmMenth: null,
        physStateMenth: null,
        mMenth: null,
        molMenth: null,
        mpMenth: null,

        chemFormulaCA: null,
        mmCA: null,
        physStateCA: null,
        mCA: null,
        molCA: null,
        mpCA: null,

        chemFormulaVan: null,
        mmVan: null,
        physStateVan: null,
        mVan: null,
        molVan: null,
        mpVan: null,

        chemFormulaHB: null,
        mmHB: null,
        physStateHB: null,
        mHB: null,
        molHB: null,
        mpHB: null,

        equipment: [],
      },
      headings: [
        {text: 'Chemical Name'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Physical States'},
        {text: 'Mass (g)'},
        {text: 'Moles'},
        {text: 'Melting Point (°C)'},
      ],
      stateOptions: ['l', 's', 'g', 'aq'],
      equipmentOptions: [
        'beaker(s)',
        'Buchner funnel',
        'buret',
        'capillary/melting tube',
        'clamp and stand',
        'cuvette',
        'Digi-Melt (melting point apparatus)',
        'digital balance',
        'disposable pipet',
        'drying oven',
        'Erlenmeyer flask',
        'filter paper',
        'funnel',
        'graduated cylinder',
        'ice',
        'KimWipes (tissues)',
        'LabQuest',
        'pencil',
        'pipet suction bulb',
        'probe (pH)',
        'probe (temperature)',
        'scintillation vial',
        'side arm flask',
        'spatula or scoopula',
        'spectrometer',
        'spotter',
        'stir bar',
        'stirrer/hotplate',
        'test tube',
        'thermometer (alcohol)',
        'TLC (developing) chamber',
        'TLC plate',
        'TLC spotter',
        'UV lamp',
        'volumetric flask',
        'volumetric pipet',
        'watch glass',
        'weighing boat or paper',
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
