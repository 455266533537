var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Enter appropriate identifying information for each chemical used during Fundamental Skills. ")]),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headings),function(head){return _c('th',{key:head.text},[_vm._v(" "+_vm._s(head.text)+" ")])}),0)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Red 40 Stock Solution")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaR40),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaR40", $$v)},expression:"inputs.chemFormulaR40"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmR40),callback:function ($$v) {_vm.$set(_vm.inputs, "mmR40", $$v)},expression:"inputs.mmR40"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateR40),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateR40", $$v)},expression:"inputs.physStateR40"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vR40),callback:function ($$v) {_vm.$set(_vm.inputs, "vR40", $$v)},expression:"inputs.vR40"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cR40),callback:function ($$v) {_vm.$set(_vm.inputs, "cR40", $$v)},expression:"inputs.cR40"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Blue 1 Stock Solution")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaB1),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaB1", $$v)},expression:"inputs.chemFormulaB1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmB1),callback:function ($$v) {_vm.$set(_vm.inputs, "mmB1", $$v)},expression:"inputs.mmB1"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateB1),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateB1", $$v)},expression:"inputs.physStateB1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vB1),callback:function ($$v) {_vm.$set(_vm.inputs, "vB1", $$v)},expression:"inputs.vB1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cB1),callback:function ($$v) {_vm.$set(_vm.inputs, "cB1", $$v)},expression:"inputs.cB1"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Yellow 5 Stock Solution")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaY5),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaY5", $$v)},expression:"inputs.chemFormulaY5"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmY5),callback:function ($$v) {_vm.$set(_vm.inputs, "mmY5", $$v)},expression:"inputs.mmY5"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateY5),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateY5", $$v)},expression:"inputs.physStateY5"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vY5),callback:function ($$v) {_vm.$set(_vm.inputs, "vY5", $$v)},expression:"inputs.vY5"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cY5),callback:function ($$v) {_vm.$set(_vm.inputs, "cY5", $$v)},expression:"inputs.cY5"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Yellow 6 Stock Solution")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaY6),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaY6", $$v)},expression:"inputs.chemFormulaY6"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmY6),callback:function ($$v) {_vm.$set(_vm.inputs, "mmY6", $$v)},expression:"inputs.mmY6"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateY6),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateY6", $$v)},expression:"inputs.physStateY6"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vY6),callback:function ($$v) {_vm.$set(_vm.inputs, "vY6", $$v)},expression:"inputs.vY6"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cY6),callback:function ($$v) {_vm.$set(_vm.inputs, "cY6", $$v)},expression:"inputs.cY6"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Deionized Water")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaH2O", $$v)},expression:"inputs.chemFormulaH2O"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "mmH2O", $$v)},expression:"inputs.mmH2O"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateH2O", $$v)},expression:"inputs.physStateH2O"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.dH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "dH2O", $$v)},expression:"inputs.dH2O"}})],1)])])]),_c('p',{staticClass:"mt-5"},[_vm._v("Select all the glassware/equipment/instrumentation used in this lab.")]),_c('v-select',{staticClass:"ml-6 mb-n4 mt-0",attrs:{"items":_vm.equipmentOptions,"multiple":"","chips":""},model:{value:(_vm.inputs.equipment),callback:function ($$v) {_vm.$set(_vm.inputs, "equipment", $$v)},expression:"inputs.equipment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }