






























import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default defineComponent({
  name: 'UMN_SandboxQ5',
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        regionA: null,
        regionB: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/UMNsandboxQ5.png';
    },
  },
});
