import { render, staticRenderFns } from "./DragToRankInput.vue?vue&type=template&id=4942d747&scoped=true&"
import script from "./DragToRankInput.vue?vue&type=script&lang=ts&"
export * from "./DragToRankInput.vue?vue&type=script&lang=ts&"
import style0 from "./DragToRankInput.vue?vue&type=style&index=0&id=4942d747&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4942d747",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})
