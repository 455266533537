<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Carbonic acid,
        <chemical-latex content="H2CO3(aq)," />
        is one of the most well-known diprotic acids. It plays a very important role in the body as
        a buffer system for the blood and gives pop that little fizz we love! Determine the
        concentration of
        <chemical-latex content="H2CO3" />
        and each of the byproducts when a
        <number-value :value="concentration" unit="\text{M}" />
        aqueous solution of carbonic acid reaches equilibrium. The two acid dissociation steps are
        as follows:
      </p>

      <v-simple-table class="mb-4 ml-4">
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="H2CO3(aq) + H2O(l) <=> HCO3^-(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              content="$\qquad\text{K}_{\text{a}_\text{1}}=4.30\times10^{-7}$"
              style="font-size: 13px"
            />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="HCO3^-(aq) + H2O(l) <=> CO3^{2-}(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              content="$\qquad\text{K}_{\text{a}_\text{2}}=4.80\times10^{-11}$"
              style="font-size: 13px"
            />
          </td>
        </tr>
      </v-simple-table>

      <calculation-input
        v-model="inputs.h2co3"
        class="mb-3"
        dense
        prepend-text="$\ce{[H2CO3]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.hco3"
        class="mb-3"
        dense
        prepend-text="$\ce{[HCO3^-]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.co3"
        class="mb-3"
        dense
        prepend-text="$\ce{[CO3^{2-}]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h3o"
        class="mb-3"
        dense
        prepend-text="$\ce{[H3O^+]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pH"
        dense
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question164',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        h2co3: null,
        hco3: null,
        co3: null,
        h3o: null,
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
