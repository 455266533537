<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the hypothetical reaction shown below:</p>
      <p class="mb-4 pl-10">
        <chemical-latex content="A(g) +" />
        <number-value :value="molB" />
        <chemical-latex content="B(g) <=>" />
        <number-value :value="molC" />
        <chemical-latex content="C(g) + " />
        <chemical-latex content="D(l)" />
      </p>

      <p class="mb-5">
        The equilibrium constant,
        <stemble-latex content="$\text{K}_{\text{p},}$" />
        for the reaction (in bar) is
        <number-value :value="Kp" />
        at
        <number-value :value="temperature" unit="\text{K.}" />
      </p>

      <p class="mb-2">
        a) What is the value of
        <stemble-latex content="$\Delta \text{n}$" />
        for this reaction?
      </p>

      <calculation-input
        v-model="inputs.Deltan"
        class="mb-2"
        dense
        prepend-text="$\Delta\text{n:}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-2">
        b) Determine the value of
        <stemble-latex content="$\text{K}_{\text{c}}$" />
        for the reaction at this temperature.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        dense
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question155',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Deltan: null,
        Kc: null,
      },
    };
  },
  computed: {
    molB() {
      return this.taskState.getValueBySymbol('molB').content;
    },
    molC() {
      return this.taskState.getValueBySymbol('molC').content;
    },
    temperature() {
      return this.taskState.getValueBySymbol('temperature').content;
    },
    Kp() {
      return this.taskState.getValueBySymbol('Kp').content;
    },
  },
};
</script>
