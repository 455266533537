<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Classify the reaction below as an Arrhenius, Brønsted-Lowry, or Lewis acid-base reaction.
        Note that the reaction may fit none, one, two, or all three of these classifications. Select
        all that apply.
      </p>

      <p class="mb-6 pl-8">
        <chemical-latex :content="balancedRxn" />
      </p>

      <p>
        <v-checkbox
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          :value="option.value"
          v-model="inputs.input1"
          :label="option.text"
          class="py-0"
          dense
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'albertaGenChemQuiz3Question37',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {text: 'Arrhenius', value: 'arrhenius'},
        {text: 'Brønsted-Lowry', value: 'bronstedLowry'},
        {text: 'Lewis', value: 'lewis'},
        {text: 'None of these options', value: 'none'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content ?? 1;
    },
    balancedRxn() {
      if (this.versionNumber.value === 1) {
        return 'H2SO4(aq) + NH3(aq) <=> HSO4-(aq) + NH4+(aq)';
      } else if (this.versionNumber.value === 2) {
        return 'OCl-(aq) + H2O(aq) <=> HOCl(aq) + OH-(aq)';
      } else if (this.versionNumber.value === 3) {
        return 'AlCl3(aq) + Cl-(aq) <=> AlCl4-(aq)';
      } else if (this.versionNumber.value === 4) {
        return 'Cu^{2+}(aq) + 4Cl-(aq) <=> CuCl4^{2-}(aq)';
      } else if (this.versionNumber.value === 5) {
        return 'CO2(aq) + H2O(l) <=> H2CO3(aq)';
      }
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
