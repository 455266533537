<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Arsenic acid is a triprotic acid that has limited applications due to its toxicity. However,
        it is used as a precursor for certain pesticides. Its chemical formula is
        <chemical-latex content="H3AsO4(aq)" />
        . If you prepare a
        <number-value :value="concentration" unit="\text{M}" />
        aqueous solution of arsenic acid, estimate the
        <stemble-latex content="$\text{pH}$" />
        of the resulting solution and the concentrations of all species at equilibrium in a once the
        system reaches equilibrium.
      </p>
      <p>The three acid dissociation steps are as follows:</p>

      <table class="mb-4 ml-4">
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="H3AsO4(aq) + H2O(l) <=> H2AsO4^-(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              content="$\qquad\text{K}_{\text{a}_\text{1}}=6.46\times10^{-3}$'"
              style="font-size: 13px"
            />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="H2AsO4^-(aq) + H2O(l) <=> HAsO4^{2-}(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              content="$\qquad\text{K}_{\text{a}_{\text{2}}=1.15\times10^{-7}$"
              style="font-size: 13px"
            />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top" class="pr-3">
            <chemical-latex
              style="font-size: 13px"
              content="HAsO4^{2-}(aq) + H2O(l) <=> AsO4^{3-}(aq) + H3O^+(aq)"
            />
          </td>
          <td style="vertical-align: top">
            <stemble-latex
              content="$\qquad\text{K}_{\text{a}_{\text{3}}=3.16\times10^{-12}$"
              style="font-size: 13px"
            />
          </td>
        </tr>
      </table>

      <calculation-input
        v-model="inputs.h3aso4"
        prepend-text="$\ce{[H3AsO4]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.h2aso4"
        prepend-text="$\ce{[H2AsO4^-]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.haso4"
        prepend-text="$\ce{[HAsO4^{2-}]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.aso4"
        prepend-text="$\ce{[AsO4^{3-}]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.h3o"
        prepend-text="$\ce{[H3O^+]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ResponseFeedback from '../response-utils/ResponseFeedback';
import TaskButtons from '../TaskButtons';
import SubmitButton from '../response-utils/SubmitButton';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question162',
  components: {
    StembleLatex,
    SubmitButton,
    TaskButtons,
    ResponseFeedback,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        h3aso4: null,
        h2aso4: null,
        haso4: null,
        aso4: null,
        h3o: null,
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
