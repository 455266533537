<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5" style="text-align: justify">
        Sodium bicarbonate,
        <chemical-latex content="NaHCO3," />
        which is commonly known as baking soda requires purification for certain applications. To
        purify sodium bicarbonate, an aqueous solution of the salt is heated to
        <stemble-latex content="$60~^\circ\text{C}$" />
        and filtered at this temperature to remove any undissolved impurities. The solution is then
        cooled to
        <stemble-latex content="$0~^\circ\text{C}$" /> to precipitate
        <chemical-latex content="NaHCO3\text{.}" /> The solution is then filtered to remove the
        solid sodium bicarbonate and leave behind any impurities that are soluble at that
        temperature. Considering the solubility of
        <chemical-latex content="NaHCO3" />
        in water decreases from
        <stemble-latex content="$164~\text{g/mL}$" /> at
        <stemble-latex content="$60~^\circ\text{C}$" /> to
        <stemble-latex content="$69~\text{g/mL}$" /> at
        <stemble-latex content="$0~^\circ\text{C,}$" /> what is the percent yield of the
        purification by this process?
      </p>

      <calculation-input
        v-model="inputs.pctYield"
        class="mb-3"
        prepend-text="$\text{Yield:}$"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question241',
  components: {
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        pctYield: null,
      },
    };
  },
};
</script>
