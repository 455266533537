


























































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default defineComponent({
  name: 'UCIrvineChem1LD_FS1_Safety1',
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        safetyMoment: '',
        emergencyScenario: null,
      },
      attachments: [] as File[],
    };
  },
  methods: {},
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
